import React, { useState, useEffect, useCallback, useReducer } from "react";
import { FormattedMessage } from "react-intl";
import { Select, MenuItem, LinearProgress } from "@material-ui/core";
import CustomTable, {
  convertToTextField,
} from "../../../components/Table/Table";
import { Link } from "react-router-dom";
import InfoPrimaryIcon from "../../../assets/icons/baseline-info-24px.svg";
import "./InventoryHome.scss";
import FeedbackAlert from "../../../components/FeedbackAlert/FeedbackAlert";
import InternetErrorComponent from "../../../components/Errors/InternetErrorComponent";
import {
  getAllProductInventoryLastMovement,
  getAllProductInventoryForTransfer,
  createMassiveProductInventoryMovement,
  createMassiveTransferOfProducts,
  createMassiveProductInventoryAdd,
} from "../../../services/utilsProductInventory";

import { getAllSalePoint } from "../../../services/utilsSalePoint";
import { convertUTCtoLocalTimeString } from "../../../components/timezonefuncions";
import { movePerishableProducts } from "../../../services/utilsTags";
import { CircularProgress } from "@mui/material";


const initialState = {
  modeStatus: 4,
  salePoints: [],
  selectedSalePoint: "",
  destinationSelectedSalePoint: "",
  tableRows: [],
  linealLoading: false,
  spLoading: false,
  linealLoadingError: false,
  rawProductsData: [],
  productsUpdate: [],
  editingInventory: false,
  openFeedbackAlert: false,
  movingPerishable: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_MODE_STATUS":
      return { ...state, modeStatus: action.payload };
    case "SET_SALE_POINTS":
      return { ...state, salePoints: action.payload };
    case "SET_SELECTED_SALE_POINT":
      return { ...state, selectedSalePoint: action.payload };
    case "SET_DESTINATION_SELECTED_SALE_POINT":
      return { ...state, destinationSelectedSalePoint: action.payload };
    case "SET_TABLE_ROWS":
      return { ...state, tableRows: action.payload };
    case "SET_LINEAL_LOADING":
      return { ...state, linealLoading: action.payload };
    case "SET_RAW_PRODUCTS_DATA":
      return { ...state, rawProductsData: action.payload };
    case "SET_PRODUCTS_UPDATE":
      return { ...state, productsUpdate: action.payload };
    case "SET_EDITING_INVENTORY":
      return { ...state, editingInventory: action.payload };
    case "SET_OPEN_FEEDBACK_ALERT":
      return { ...state, openFeedbackAlert: action.payload };
    case "SET_MOVE_PERISHIABLE":
        return { ...state, movingPerishable: action.payload };
    // Add more cases as needed
    default:
      return state;
  }
};

const InventoryHome = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    modeStatus,
    selectedSalePoint,
    destinationSelectedSalePoint,
    tableRows,
    linealLoading,
    spLoading,
    linealLoadingError,
    productsUpdate,
    editingInventory,
    openFeedbackAlert,
    movingPerishable
  } = state;

  useEffect(() => {
    fetchSalePoints();
  }, []);

  useEffect(() => {
    if (selectedSalePoint) {
      if (modeStatus < 3) {
        if (!state.rawProductsData) {
          fetchProductCurrentInventory(selectedSalePoint);
        }
      }
    }
  }, [modeStatus]);

  useEffect(() => {
    if (modeStatus === 3) {
      dispatch({ type: "SET_TABLE_ROWS", payload: [] });
      dispatch({ type: "SET_DESTINATION_SELECTED_SALE_POINT", payload: "" });
    }
  }, [modeStatus]);

  useEffect(() => {
    if (selectedSalePoint && destinationSelectedSalePoint) {
      fetchProductCurrentInventoryTrasnfer(
        selectedSalePoint,
        destinationSelectedSalePoint
      );
    }
  }, [selectedSalePoint, destinationSelectedSalePoint]);

  useEffect(() => {
    // Reset all state values when selectedSalePoint changes
    dispatch({ type: "SET_MODE_STATUS", payload: 4 });
    dispatch({ type: "SET_DESTINATION_SELECTED_SALE_POINT", payload: "" });
    dispatch({ type: "SET_TABLE_ROWS", payload: [] });
    dispatch({ type: "SET_RAW_PRODUCTS_DATA", payload: [] });
  }, [selectedSalePoint]);

  useEffect(() => {
    if (state.rawProductsData && modeStatus !== 3 && !editingInventory) {
      let dataReady = state.rawProductsData.map((product) => {
        let rowData;
        switch (modeStatus) {
          case 0:
            rowData = createRowForMode0(
              product.image,
              product.name,
              product.sku,
              product.current_cuantity,
              product.last_date,
              product.fk_product
            );
            break;
          case 1:
            rowData = createRowForMode1(
              product.image,
              product.name,
              product.sku,
              product.current_cuantity,
              product.last_date,
              product.fk_product
            );
            break;
          case 2:
            rowData = createRowForMode2(
              product.image,
              product.name,
              product.sku,
              product.current_cuantity,
              product.last_date,
              product.current_cuantity,
              product.fk_product
            );
            break;
          default:
            rowData = null;
        }
        return rowData;
      });
      dispatch({ type: "SET_TABLE_ROWS", payload: dataReady });
    }
  }, [modeStatus, state.rawProductsData, editingInventory]);

  // Memoized callbacks
  const handleSelectedSalePoint = useCallback(
    async (e) => {
      const value = e.target.value;
      await dispatch({ type: "SET_SELECTED_SALE_POINT", payload: value });
      await localStorage.setItem("fk_sale_point", value);

      // if (modeStatus !== undefined) {
      //   dispatch({ type: "SET_MODE_STATUS", payload: 0 });
      // }

      dispatch({ type: "SET_RAW_PRODUCTS_DATA", payload: undefined });
      dispatch({ type: "SET_TABLE_ROWS", payload: [] });

      console.log(localStorage.getItem("fk_sale_point"));
    },
    [modeStatus]
  );

  const handleSubmitNewInventory = (e) => {
    e.preventDefault();
    console.log("Cancel Edit");
    dispatch({ type: "SET_LINEAL_LOADING", payload: true });
    let movementType = 0;
    if (modeStatus === 2) {
      movementType = 1;
    }
    console.log(productsUpdate);
    let fk_supervisor = JSON.parse(localStorage.getItem("profile"))["profile"][
      "user"
    ]["supervisor"]["id"];

    if (modeStatus !== 3) {
      if (modeStatus !== 2) {
        let jsonBody = {
          fk_sale_point: selectedSalePoint,
          movement_type: movementType,
          products: productsUpdate,
          fk_supervisor: fk_supervisor,
        };
        createMassiveProductInventoryMovement(JSON.stringify(jsonBody)).then(
          (data) => {
            console.log("SE CREO EL MOVIMIENTO DE INVENTARIO", data);
            fetchProductCurrentInventory(selectedSalePoint);
            dispatch({ type: "SET_OPEN_FEEDBACK_ALERT", payload: true });
            dispatch({ type: "SET_MODE_STATUS", payload: 0 });
            dispatch({ type: "SET_EDITING_INVENTORY", payload: false });
            // setLinealLoading(false);
            dispatch({ type: "SET_PRODUCTS_UPDATE", payload: [] });
          }
        );
      } else {
        let jsonBody = {
          fk_sale_point: selectedSalePoint,
          movement_type: movementType,
          products: productsUpdate,
          fk_supervisor: fk_supervisor,
        };
        createMassiveProductInventoryAdd(JSON.stringify(jsonBody)).then(
          (data) => {
            console.log("SE CREO EL MOVIMIENTO DE INVENTARIO", data);
            fetchProductCurrentInventory(selectedSalePoint);
            dispatch({ type: "SET_OPEN_FEEDBACK_ALERT", payload: true });
            dispatch({ type: "SET_MODE_STATUS", payload: 0 });
            dispatch({ type: "SET_EDITING_INVENTORY", payload: false });
            // setLinealLoading(false);
            dispatch({ type: "SET_PRODUCTS_UPDATE", payload: [] });
          }
        );
      }
    } else {
      const productsTransfer = productsUpdate.map((obj) => {
        const { fk_product, new_quantity } = obj;
        const int_quantity = +new_quantity;

        return { fk_product: fk_product, quantity: int_quantity };
      });
      console.log(productsTransfer);
      console.log("entro aqui");
      let jsonBody = {
        fk_sale_point_source: selectedSalePoint,
        fk_sale_point_destination: destinationSelectedSalePoint,
        transaction_type: 0,
        products: productsTransfer,
        fk_supervisor: fk_supervisor,
      };
      console.log(jsonBody);
      createMassiveTransferOfProducts(JSON.stringify(jsonBody)).then((data) => {
        console.log("SE CREO EL MOVIMIENTO DE INVENTARIO", data);
        fetchProductCurrentInventory(selectedSalePoint);
        dispatch({ type: "SET_OPEN_FEEDBACK_ALERT", payload: true });
        dispatch({ type: "SET_MODE_STATUS", payload: 0 });
        dispatch({ type: "SET_EDITING_INVENTORY", payload: false });
        // setLinealLoading(false);
        dispatch({ type: "SET_PRODUCTS_UPDATE", payload: [] });
      });
    }
  };

  const handleModePerishableProducts = async (e) => {
    e.preventDefault();
    dispatch({ type: "SET_MOVE_PERISHIABLE", payload: true });
    let fkSupervisor = JSON.parse(localStorage.getItem("profile")).profile.user
      .supervisor.id;
    let jsonBody = {
      fk_origin_sale_point: selectedSalePoint,
      fk_destiny_sale_point: destinationSelectedSalePoint,
      fk_supervisor: fkSupervisor,
    };
    let move = await movePerishableProducts(JSON.stringify(jsonBody));
    if(move.moved_products){ 
      dispatch({ type: "SET_MOVE_PERISHIABLE", payload: false });
    }
    console.log(move);

    console.log("LLamar al servicio de Santi");
  };

  const handleChangeCurrentInventoryMode1 = (event, rows, rowId) => {
    let jsonRow = { fk_product: rowId, new_quantity: event.target.value };
    console.log("Hambre");

    let newRows = rows.map((row) => {
      if (row.currentIntentory[3] === rowId) {
        row.currentIntentory[0] = event.target.value;
      }
      return row;
    });
    console.log("Hambre", rowId, event.target.value);

    if (!editingInventory) {
      dispatch({ type: "SET_EDITING_INVENTORY", payload: true });
    }

    dispatch({ type: "SET_TABLE_ROWS", payload: newRows });

    console.log("BEFORE", productsUpdate);
    // let updatedProducts = [...productsUpdate];

    let existingProductIndex = productsUpdate.findIndex(
      (product) => product.fk_product === rowId
    );

    if (existingProductIndex !== -1) {
      // Update the existing item
      productsUpdate[existingProductIndex] = {
        ...productsUpdate[existingProductIndex],
        ...jsonRow,
      };
    } else {
      // Item doesn't exist, add it to updatedProducts
      productsUpdate.push(jsonRow);
    }

    console.log("hambre", productsUpdate);

    dispatch({ type: "SET_PRODUCTS_UPDATE", payload: productsUpdate });
  };

  const handleAddCurrentInventory = (event, rows, rowId) => {
    let eventValue = parseFloat(event.target.value);

    if (eventValue >= 0) {
      let newRows = rows.map((row) => {
        if (row.addInventory[3] === rowId) {
          let currentQuantity = row.currentInventory;

          row.addInventory[0] = parseFloat(eventValue);
          row.newCurrentInventory = eventValue + currentQuantity;
        }
        return row;
      });

      let jsonRow = { fk_product: rowId, new_quantity: eventValue };

      if (!editingInventory) {
        dispatch({ type: "SET_EDITING_INVENTORY", payload: true });
      }

      dispatch({ type: "SET_TABLE_ROWS", payload: newRows });

      console.log("BEFORE", productsUpdate);
      // let updatedProducts = [...productsUpdate];

      let existingProductIndex = productsUpdate.findIndex(
        (product) => product.fk_product === rowId
      );

      if (existingProductIndex !== -1) {
        // Update the existing item
        productsUpdate[existingProductIndex] = {
          ...productsUpdate[existingProductIndex],
          ...jsonRow,
        };
      } else {
        // Item doesn't exist, add it to updatedProducts
        productsUpdate.push(jsonRow);
      }

      console.log("hambre", productsUpdate);

      dispatch({ type: "SET_PRODUCTS_UPDATE", payload: productsUpdate });
    }
  };

  const handleCancelEdit = (e) => {
    e.preventDefault();
    console.log("cancel");

    dispatch({ type: "SET_EDITING_INVENTORY", payload: false });
    dispatch({ type: "SET_PRODUCTS_UPDATE", payload: [] });
  };

  const handleTransferQuantity = (event, rows, rowId) => {
    let eventValue = parseInt(event.target.value);
    let newSource, newDestination;
    let jsonRow = { fk_product: rowId, new_quantity: event.target.value };

    if (eventValue >= 0) {
      let newRows = rows.map((row) => {
        if (row.transferQuantity[3] === rowId) {
          let sourceQuantity = parseInt(row["sourceString"].split(" ")[0]);
          let destinationQuantity = parseInt(
            row["destinationString"].split(" ")[0]
          );
          if (sourceQuantity > 0) {
            newSource = sourceQuantity - eventValue + " UND";
            newDestination = destinationQuantity + eventValue + " UND";

            row.transferQuantity[0] = parseInt(eventValue);
            row.newSourceQuantity = newSource;
            row.newDestinationQuanity = newDestination;
            if (!editingInventory) {
              dispatch({ type: "SET_EDITING_INVENTORY", payload: true });
            }
          }
        }
        return row;
      });
      console.log(newRows);
      dispatch({ type: "SET_TABLE_ROWS", payload: newRows });
    }

    console.log("BEFORE", productsUpdate);
    // let updatedProducts = [...productsUpdate];

    let existingProductIndex = productsUpdate.findIndex(
      (product) => product.fk_product === rowId
    );

    if (existingProductIndex !== -1) {
      // Update the existing item
      productsUpdate[existingProductIndex] = {
        ...productsUpdate[existingProductIndex],
        ...jsonRow,
      };
    } else {
      // Item doesn't exist, add it to updatedProducts
      productsUpdate.push(jsonRow);
    }

    console.log("hambre", productsUpdate);

    dispatch({ type: "SET_PRODUCTS_UPDATE", payload: productsUpdate });
  };

  // Import necessary dependencies

  // Function to fetch sale points
  const fetchSalePoints = async () => {
    try {
      // Set loading state
      let fkOrganization = localStorage.getItem("fk_organization");

      dispatch({ type: "SET_LINEAL_LOADING", payload: true });

      // Call API to get sale points
      const response = await getAllSalePoint(fkOrganization);
      // Extract sale points from the response
      const salePoints = response.sale_points.map((point) => ({
        label: point.name,
        value: point.id,
      }));

      // Set sale points in the state
      dispatch({ type: "SET_SALE_POINTS", payload: salePoints });

      // Clear loading state
      dispatch({ type: "SET_LINEAL_LOADING", payload: false });
    } catch (error) {
      // Handle error
      console.error("Error fetching sale points:", error);

      // Set error state
      dispatch({ type: "SET_LINEAL_LOADING_ERROR", payload: true });
    }
  };

  // Function to fetch current inventory for a selected sale point
  const fetchProductCurrentInventory = async (salePointId) => {
    try {
      // Set loading state
      dispatch({ type: "SET_LINEAL_LOADING", payload: true });

      // Call API to get product inventory for the selected sale point
      const response = await getAllProductInventoryLastMovement(salePointId);

      // Set raw products data in the state
      dispatch({ type: "SET_RAW_PRODUCTS_DATA", payload: response.products });

      // Extract relevant data from the response
      const tableRows = response.products.map((product) =>
        createRowForMode0(
          product.image,
          product.name,
          product.sku,
          product.current_cuantity,
          product.last_date,
          product.fk_product
        )
      );
      // Set table rows based on raw products data
      console.log("tableRows", tableRows);

      // Set table rows in the state
      dispatch({ type: "SET_TABLE_ROWS", payload: tableRows });

      // Clear loading state
      dispatch({ type: "SET_LINEAL_LOADING", payload: false });
    } catch (error) {
      // Handle error
      console.error("Error fetching product current inventory:", error);

      // Set error state
      dispatch({ type: "SET_LINEAL_LOADING_ERROR", payload: true });
    }
  };

  // Function to fetch product current inventory for transfer
  const fetchProductCurrentInventoryTrasnfer = async (
    sourceSalePointId,
    destinationSalePointId
  ) => {
    try {
      // Set loading state
      dispatch({ type: "SET_LINEAL_LOADING", payload: true });

      // Call API to get product inventory for the selected sale point
      const response = await getAllProductInventoryForTransfer(
        sourceSalePointId,
        destinationSalePointId
      );
      console.log(response);
      // Extract relevant data from the response
      const rawProductsData = response.products.map((product) =>
        createRowForMode3(
          product.image,
          product.name,
          product.source_quantity,
          product.destination_quantity,
          product.fk_product
        )
      );

      // Set raw products data in the state
      dispatch({ type: "SET_RAW_PRODUCTS_DATA", payload: response.products });

      // Set table rows in the state
      dispatch({ type: "SET_TABLE_ROWS", payload: rawProductsData });

      // Clear loading state
      dispatch({ type: "SET_LINEAL_LOADING", payload: false });
    } catch (error) {
      // Handle error
      console.error(
        "Error fetching product current inventory for transfer:",
        error
      );

      // Set error state
      dispatch({ type: "SET_LINEAL_LOADING_ERROR", payload: true });
    }
  };

  //-----------------------------------------------
  // TABLE MANGEMENT           --------------------
  //-----------------------------------------------

  const renderModeTable = () => {
    let inventoryTableHeaders;
    switch (modeStatus) {
      case 0:
        inventoryTableHeaders = [
          " ",
          "nameHeader",
          "skuHeader",
          "dateHeader",
          "currentQuantityHeader",

          "actionsHeader",
        ];

        return (
          <CustomTable
            headers={inventoryTableHeaders}
            rows={tableRows}
            rowTypes={[[0, "image"]]}
            pagination={""}
          />
        );

      case 1:
        inventoryTableHeaders = [
          " ",
          "nameHeader",
          "skuHeader",
          "dateHeader",
          "currentQuantityHeader",
          "actionsHeader",
        ];

        return (
          <CustomTable
            headers={inventoryTableHeaders}
            rows={tableRows}
            rowTypes={[
              [0, "image"],
              [4, "textfield-number"],
            ]}
            pagination={""}
          />
        );

      case 2:
        inventoryTableHeaders = [
          " ",
          "nameHeader",
          "skuHeader",
          "dateHeader",
          "currentQuantityHeader",
          "addHeader",
          "newQuantityHeader",
          "actionsHeader",
        ];

        return (
          <CustomTable
            headers={inventoryTableHeaders}
            rows={tableRows}
            rowTypes={[
              [0, "image"],
              [5, "textfield-number"],
            ]}
            pagination={""}
          />
        );
      case 3:
        inventoryTableHeaders = [
          " ",
          "nameHeader",
          "currentSourceQuantityHeader",
          "currentDestinationQuantityHeader",
          "transferQauntityHeader",
          "newQuantitySourceHeader",
          "newQuantityDestinationHeader",
          "actionsHeader",
        ];

        return (
          <CustomTable
            headers={inventoryTableHeaders}
            rows={tableRows}
            rowTypes={[
              [0, "image"],
              [4, "textfield-number"],
            ]}
            pagination={""}
          />
        );

      default:
        return null;
    }
  };

  const createRowForMode0 = (
    img,
    name,
    sku,
    currentInventory,
    date,
    id
  ) => {
    // let lastMovements = getMovementTypeString(lastMovement);
    let currentInventoryString = currentInventory + " UND";
    const localTimeString = convertUTCtoLocalTimeString(date);
    return {
      img,
      name,
      sku,
      localTimeString,
      currentInventoryString,
      actions: (
        <>
          <div className="flex">
            <Link to={`/inventarios/${id}`} onClick={() => goProduct(id)}>
              <img
                src={InfoPrimaryIcon}
                alt="info"
                onClick={() => ""}
                className="table-icon"
              />
            </Link>
          </div>
        </>
      ),
    };
  };

  const createRowForMode1 = (
    img,
    name,
    sku,
    currentInventory,
    date,
    id
  ) => {
    // let lastMovements = getMovementTypeString(lastMovement);
    const localTimeString = convertUTCtoLocalTimeString(date);

    return {
      img,
      name,
      sku,
      localTimeString,
      currentIntentory: convertToTextField(
        currentInventory,
        "currentInventory",
        handleChangeCurrentInventoryMode1,
        id
      ),
      actions: (
        <>
          <div className="flex">
            <a
              href={`/inventarios/${id}`}
              onClick={(event) => {
                // Prevent default if goProduct() needs to do something before navigating
                event.preventDefault();
                goProduct(id);
                // This will manually open the link in a new tab
                window.open(`/inventarios/${id}`, "_blank");
              }}
            >
              <img src={InfoPrimaryIcon} alt="info" className="table-icon" />
            </a>
          </div>
        </>
      ),
    };
  };

  const createRowForMode2 = (
    img,
    name,
    sku,
    currentInventory,
    date,
    newCurrentInventory,
    id
  ) => {
    // let lastMovements = getMovementTypeString(lastMovement);
    const localTimeString = convertUTCtoLocalTimeString(date);

    return {
      img,
      name,
      sku,
      localTimeString,
      currentInventory,
      addInventory: convertToTextField(
        0,
        "addInventory",
        handleAddCurrentInventory,
        id
      ),
      newCurrentInventory,
      actions: (
        <>
          <div className="flex">
            <a
              href={`/inventarios/${id}`}
              onClick={(event) => {
                // Prevent default if goProduct() needs to do something before navigating
                event.preventDefault();
                goProduct(id);
                // This will manually open the link in a new tab
                window.open(`/inventarios/${id}`, "_blank");
              }}
            >
              <img src={InfoPrimaryIcon} alt="info" className="table-icon" />
            </a>
          </div>
        </>
      ),
    };
  };

  const createRowForMode3 = (
    img,
    name,
    sourceQuantity,
    destinationQuantity,
    id
  ) => {
    let sourceString = sourceQuantity + " UND";
    let destinationString = destinationQuantity + " UND";
    return {
      img,
      name,
      sourceString,
      destinationString,
      transferQuantity: convertToTextField(
        0,
        "transferQuanitity",
        handleTransferQuantity,
        id
      ),
      newSourceQuantity: sourceString,
      newDestinationQuanity: destinationString,
      actions: (
        <>
          <div className="flex">
            <a
              href={`/inventarios/${id}`}
              onClick={(event) => {
                // Prevent default if goProduct() needs to do something before navigating
                event.preventDefault();
                goProduct(id);
                // This will manually open the link in a new tab
                window.open(`/inventarios/${id}`, "_blank");
              }}
            >
              <img src={InfoPrimaryIcon} alt="info" className="table-icon" />
            </a>
          </div>
        </>
      ),
    };
  };
  //-----------------------------------------------
  // Aux funtions              --------------------
  //-----------------------------------------------

  const getMovementTypeString = (movementObj) => {
    if (!movementObj) return "Sin movimientos";

    const { transaction_type, movement_type, moved_quantity } = movementObj;

    switch (transaction_type) {
      case 0:
        switch (movement_type) {
          case 1:
            return `Adición (+${moved_quantity} UND)`;
          case 0:
            return `Forzado (${moved_quantity} UND)`;
          default:
            return `Entrega (${moved_quantity} UND)`;
        }
      case 1:
        return `Venta (${moved_quantity} UND)`;
      case 2:
        return movement_type === 1
          ? `Traslado (+${moved_quantity} UND)`
          : `Traslado (-${moved_quantity} UND)`;
      default:
        return "Sin movimientos";
    }
  };

  let goProduct = (id) => {
    localStorage.setItem("fk_product", id);
    localStorage.setItem("fk_sale_point", selectedSalePoint);
  };

  return (
    <main className="section-body inventory-container">
      {/* Loading indicators */}

      {/* Selectors */}
      <div className="row">
        <div className="col-3 mb-4">
          <label className="form-label" htmlFor="franchiseInput">
            <FormattedMessage id="franchiseLbl" />
          </label>
          <Select
            required
            value={selectedSalePoint ? selectedSalePoint : ""}
            name="fk_sale_point"
            onChange={handleSelectedSalePoint}
            className="worker-form-select"
            id="salePointInput"
            disabled={editingInventory || spLoading}
          >
            {state.salePoints.map((salePoint, i) => (
              <MenuItem key={i} value={salePoint.value}>
                {salePoint.label}
              </MenuItem>
            ))}
          </Select>
        </div>
        {/* Mode Selector */}
        <div className="col-3 mb-4">
          <label className="form-label" htmlFor="modeInput">
            <FormattedMessage id="modelbl" />
          </label>
          <Select
            required
            value={modeStatus}
            name="mode"
            onChange={(e) =>
              dispatch({ type: "SET_MODE_STATUS", payload: e.target.value })
            }
            className="worker-form-select"
            id="modeInput"
            disabled={
              editingInventory || spLoading || state.salePoints.lenght === 0
            }
          >
            <MenuItem key={4} value={4}>
              Seleccionar modo
            </MenuItem>
            <MenuItem key={0} value={0}>
              <FormattedMessage id="modeNormalViewInventorylbl" />
            </MenuItem>
            <MenuItem key={1} value={1}>
              <FormattedMessage id="modeForcedInventorylbl" />
            </MenuItem>
            <MenuItem key={2} value={2}>
              <FormattedMessage id="modeAddInventorylbl" />
            </MenuItem>
            <MenuItem key={3} value={3}>
              <FormattedMessage id="modeTransferlbl" />
            </MenuItem>

            {/* Add more menu items as needed */}
          </Select>
        </div>
        {/* Destination Sale Point Selector */}
        {modeStatus === 3 && (
          <div className="col-3 mb-4">
            <label className="form-label" htmlFor="destSalePointInput">
              <FormattedMessage id="destiantionSalePointLbl" />
            </label>
            <Select
              required
              value={
                destinationSelectedSalePoint ? destinationSelectedSalePoint : ""
              }
              name="destinationSalePoint"
              onChange={(e) =>
                dispatch({
                  type: "SET_DESTINATION_SELECTED_SALE_POINT",
                  payload: e.target.value,
                })
              }
              className="worker-form-select"
              id="destSalePointInput"
              disabled={editingInventory || spLoading}
            >
              {state.salePoints
                // Filter out the sale points based on the condition
                .filter((salePoint) => salePoint.value !== selectedSalePoint)
                // Map the filtered sale points to create the menu items
                .map((salePoint, i) => (
                  <MenuItem key={i} value={salePoint.value}>
                    {salePoint.label}
                  </MenuItem>
                ))}
            </Select>
          </div>
        )}

        <div className="col-3 mb-4">
          <form>
            {editingInventory && (
              <div className="row justify-content-end">
                <div className="col-12 d-flex justify-content-end align-items-end">
                  <button
                    className="btn btn-primary m-2"
                    type="submit"
                    onClick={handleSubmitNewInventory}
                  >
                    <FormattedMessage id="savebutton" />
                  </button>
                  <button
                    className="btn btn-outline-primary m-2"
                    onClick={handleCancelEdit}
                  >
                    <FormattedMessage id="cancelLbl" />
                  </button>
                </div>
              </div>
            )}
            {modeStatus === 3 &&
              !editingInventory &&
              destinationSelectedSalePoint && (
                <div className="row justify-content-end">
                  <div className="col-12 d-flex justify-content-end align-items-end">
                    <button
                      className="btn btn-primary m-2"
                      type="submit"
                      onClick={handleModePerishableProducts}
                      disabled={movingPerishable}
                    >
                      {!movingPerishable && (<div>MOVER PERECEDEROS</div>)}
                      {movingPerishable && <CircularProgress color="inherit" />}

                    </button>
                  </div>
                </div>
              )}
          </form>
        </div>
      </div>
      {(linealLoading || spLoading) && !linealLoadingError && (
        <LinearProgress />
      )}
      {linealLoadingError && <InternetErrorComponent />}
      {/* Table */}
      {modeStatus !== 3 && selectedSalePoint && tableRows && renderModeTable()}

      {modeStatus === 3 &&
        tableRows &&
        destinationSelectedSalePoint &&
        selectedSalePoint &&
        renderModeTable()}

      {/* Feedback Alert */}
      {openFeedbackAlert && (
        <FeedbackAlert
          type="success"
          message={<FormattedMessage id="inventoryCreated" />}
          onClose={() =>
            dispatch({ type: "SET_OPEN_FEEDBACK_ALERT", payload: false })
          }
        />
      )}

      {/* Rest of the UI */}
    </main>
  );
};

export default InventoryHome;
