import { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Input,
} from "@mui/material";
import { getCritialProvidersBySupervisor } from "../../services/utilsProvider/utilsProvider";
import { getSalePointsOfOrganization } from "../../services/utilsFranchise/utilsFranchise";

// import { DataGrid } from "@mui/x-data-grid";
import "./StockPage.scss";
import LinearProgress from "@material-ui/core/LinearProgress";

import SearchBar from "../../components/searchBar/searchBar";

const CriticalProducts = () => {
  const [productsData, setProductsData] = useState([]);
  const [productDataDefatult, setProductDataDefault] = useState([]);

  const [franchisesData, setFranshisesData] = useState([]);

  const [franchiseSelected, setFranchiseSelected] = useState(0);

  const [loadingTable, setLoadingTable] = useState(false);

  let rowsId = 1;
  const columns = [
    { field: "name", headerName: "Producto", width: 330 },
    { field: "franchiseName", headerName: "Punto de venta", width: 130 },
    { field: "provider", headerName: "Proveedores", width: 250 },
    {
      field: "in_stock",
      headerName: "En tienda",
      type: "number",
      width: 90,
    },
    {
      field: "sales",
      headerName: "Ventas",
      type: "number",
      width: 90,
    },
    {
      field: "sales_days",
      headerName: "Ventas / dia",
      type: "number",
      width: 110,
    },
    {
      field: "suggested_order",
      headerName: "Pedido sugerido",
      type: "number",
      width: 150,
    },
  ];


  let transformProducts = (provider) => {
    let nameProvider = provider.name;
    let productsFranchises = [];
    provider.products[0].map((product) => {
      Object.entries(product).forEach(([key, value]) => {
        if (key === "name" || key === "sku") {
        } else {
          let productObj = {
            id: rowsId,
            name: product["name"],
            sku: product["sku"],
            provider: nameProvider,
            franchiseName: key,
            in_stock: value.in_stock,
            sales: value.sales_dates,
            sales_days: value.sales_day,
            suggested_order: value.suggested_order,
            [key]: value,
          };
          rowsId++;
          productsFranchises.push(productObj);
        }
      });
    });

    return productsFranchises;
  };

  const handleChangeFranchise = (event) => {
    setFranchiseSelected(event.target.value);
    if (event.target.value === 0) {
      setProductsData(productDataDefatult);
    } else {
      let franchiseFound = franchisesData.find(
        (franchise) => franchise.id === event.target.value
      );
      let filteredProducts = productDataDefatult.filter((product) => {
        if (product.franchiseName === franchiseFound.name) {
          return product;
        }
      });
      setProductsData(filteredProducts);
    }
  };

  const onChangeSearch = async (event) => {
    let input = event.target.value;
    const filteredProducts = productDataDefatult.filter((product) => {
      return (
        product.name.toLowerCase().includes(input.toLowerCase()) ||
        product.provider.toLowerCase().includes(input.toLowerCase())
      );
    });
    setProductsData(filteredProducts);
  };

  const fetchData = () => {
    let fk_supervisor = localStorage.getItem("fk_supervisor");
    setLoadingTable(true);
    getSalePointsOfOrganization().then((data) => {
      console.log(data, "fetchfranchises");
      setFranshisesData(data["franchises"]);
    });
    
    getCritialProvidersBySupervisor(fk_supervisor).then((data)=>{
        console.log("This is the data", data);
        let newData = data.flatMap((provider) => transformProducts(provider));
        setProductsData(newData);
        setProductDataDefault(newData);
        setLoadingTable(false);
        // setProductsData(data);
        // setCriticalProductsProvider(data.products[0])
    });
    
    
    
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      <div className="page-container">
        <FormControl variant="standard" style={{ width: "20%", margin: "8px" }}>
          <InputLabel id="demo-simple-select-standard-label" color="secondary">
            Punto de venta
          </InputLabel>

          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={franchiseSelected}
            onChange={handleChangeFranchise}
            label="Punto de venta"
            color="secondary"
            className="input"
          >
            <MenuItem value={0} key={0}>
              Todas
            </MenuItem>
            {franchisesData.map((franchise, indexFranchise) => {
              return (
                <MenuItem value={franchise.id} key={indexFranchise}>
                  {franchise.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <FormControl variant="standard" style={{ width: "76%", margin: "8px" }}>
          <TextField
            id="standard-basic"
            label="Producto o proveedor"
            variant="standard"
            color="secondary"
            onChange={onChangeSearch}
          />
        </FormControl>
        {loadingTable && <LinearProgress/>}
        {!loadingTable && <div className="grid-cointainder">
          <div style={{ height: 650, width: "100%" }}>
            {/* <DataGrid
              rows={productsData}
              columns={columns}
              pageSize={20}
              rowsPerPageOptions={[5]}
              checkboxSelection
            /> */}
          </div>
        </div>}
      </div>
    </div>
  );
};

export default CriticalProducts;
