import React, { useEffect, useState, useRef } from "react";
import ReactApexChart from "react-apexcharts";
import "./GraphInventoryComponent.scss";
import { getProductInventoryMovements } from "../../../services/utilsProductInventory";
import { useTable } from "react-table";
import CircularProgress from "@mui/material/CircularProgress";
import * as d3 from "d3";
import { Padding } from "@mui/icons-material";
import { ResponsiveLine } from '@nivo/line'

const GraphInventoryComponent = (props) => {
  const today = new Date();
  const lastMonth = new Date(today);
  lastMonth.setMonth(today.getMonth() - 1);

  const [selectedOption, setSelectedOption] = useState("tabla");
  const [tableLoading, setTableLoading] = useState(true);
  const [dataGraph, setDataGraph] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [movements, setMovements] = useState([]);
  const [salePoint, setSalePoint] = useState(props.selectedSalePoint);
  const [selectedDate, setSelectedDate] = useState(
    lastMonth.toISOString().substr(0, 10)
  );
  const [selectedFinalDate, setSelectedFinalDate] = useState(
    new Date().toISOString().substr(0, 10)
  );

  const [dataGraph2, setDataGraph2] = useState([
    {
      "id": "product",
      "color": "hsl(25, 70%, 50%)",
      "data": [
        {
          "x": "plane",
          "y": 89
        },
        {
          "x": "helicopter",
          "y": 70
        },
        {
          "x": "boat",
          "y": 109
        },
        {
          "x": "train",
          "y": 242
        },
        {
          "x": "subway",
          "y": 79
        },
        {
          "x": "bus",
          "y": 188
        },
        {
          "x": "car",
          "y": 206
        },
        {
          "x": "moto",
          "y": 283
        },
        {
          "x": "bicycle",
          "y": 101
        },
        {
          "x": "horse",
          "y": 133
        },
        {
          "x": "skateboard",
          "y": 284
        },
        {
          "x": "others",
          "y": 32
        }
      ]
    }
  ]);

  const movementsOptions = {
    0: "Traslado",
    1: "Adición",
    2: "Baja",
  };

  const handleChangeDate = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleChangeFinalDate = async (event) => {
    setSelectedFinalDate(event.target.value);
  };

  const fetchData = () => {
    setTableLoading(true);
    getProductInventoryMovements(
      props.fk_product,
      props.selectedSalePoint.id,
      selectedDate,
      selectedFinalDate
    )
      .then((data) => {
        setMovements(data.movements);
        let movements = data.movements.map((movement) => {
          let date = movement.datetime.split(" ")[0];
          return [date, movement.new_quantity];
        });
        let graphData = data.dates_quantity;

        console.log(data);
        console.log(movements);

        console.log(graphData);
        setDataGraph2([
          {
            "id": "product",
            "color": "hsl(25, 70%, 50%)",
            "data": graphData
          }
        ]);

        const fechasDiccionario = {};

        for (let i = 0; i < movements.length; i++) {
          const [fecha, numero] = movements[i];
          fechasDiccionario[fecha] = i;
        }

        const fechasOrdenadas = Object.keys(fechasDiccionario).sort();

        const arregloLimpio = fechasOrdenadas.map((fecha) => {
          const posicion = fechasDiccionario[fecha];
          return movements[posicion];
        });

        movements = arregloLimpio;

        setDataGraph(movements);
        setTableLoading(false);
      })
      .catch((error) => {
        setMovements([]);
        setDataGraph([]);
        setTableLoading(false);
      });
  };







  useEffect(() => {
    fetchData();
  }, [selectedFinalDate, selectedDate]);

  useEffect(() => {
    fetchData();
  }, [props.selectedSalePoint]);

  // useEffect(() => {
  //   console.log(props.selectedSalePoint);
  //   fetchData();
  // }, [props.selectedSalePoint]);

  const series = [
    {
      name: "Series 1",
      data: dataGraph.sort((a, b) => new Date(a[0]) - new Date(b[0])),
    },
  ];

  return (
    <div className="p-i-grapg-cont">
      <span className="historic-movements-span">Historico de Movimientos</span>
      <div className="date-input-div">
        <label className="input-date-inventory-product">
          <input
            type="date"
            className="worker-form-select"
            value={selectedDate}
            onChange={handleChangeDate}
          />
        </label>
        <label className="input-date-inventory-product">
          <input
            type="date"
            className="worker-form-select"
            value={selectedFinalDate}
            onChange={handleChangeFinalDate}
          />
        </label>
      </div>
      <div className="option-inventory-product-options-2">
        <label className="input-label-inventory-product">
          <input
            type="radio"
            value="grafico"
            checked={selectedOption === "grafico"}
            onChange={() => setSelectedOption("grafico")}
            className="input-radio-pi-2"
          />
          Gráfico
        </label>
        <label className="input-label-inventory-product">
          <input
            type="radio"
            value="tabla"
            checked={selectedOption === "tabla"}
            onChange={() => setSelectedOption("tabla")}
            className="input-radio-pi"
          />
          Tabla
        </label>
      </div>
      {!tableLoading ? (
        <div>
          {selectedOption === "grafico" ? (
            <div>
              {/* <LineChart data={[10,40,10,30,20]} dates ={["12","23","45","78","56"]}></LineChart> */}
              <MyResponsiveLine data={dataGraph2}></MyResponsiveLine>
            </div>
          ) : (
            <table className="table-p-inv">
              <thead className="table-thead">
                <tr>
                  <th className="fond">Fecha</th>
                  <th className="fond">Tipo de Movimiento</th>
                  <th className="fond">Cantidad</th>
                  <th className="fond">Saldo Cantidad</th>
                </tr>
              </thead>
              <tbody>
                {movements.map((movement, index) => (
                  <tr className="p-i-rowtable" key={index}>
                    <td className="fond">{movement.datetime}</td>
                    <td className="fond">
                      {movementsOptions[movement.movement_type]}
                    </td>
                    <td className="fond">{movement.add_quantity}</td>
                    <td className="fond">{movement.new_quantity}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      ) : (
        <CircularProgress></CircularProgress>
      )}
    </div>
  );
};


const MyResponsiveLine = ({ data }) => (
  <div style={{width:"auto",height:"400px"}}>
    
  <ResponsiveLine
    data={data}
    margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
    xScale={{ type: 'point' }}
    yScale={{
      type: 'linear',
      min: 'auto',
      max: 'auto',
      stacked: true,
      reverse: false
    }}
    yFormat=" >-.2f"
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Día',
      legendOffset: 36,
      legendPosition: 'middle'
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: 'Cantidad',
      legendOffset: -40,
      legendPosition: 'middle'
    }}
    pointSize={10}
    pointColor={{ theme: 'background' }}
    pointBorderWidth={2}
    pointBorderColor={{ from: 'serieColor' }}
    pointLabelYOffset={-12}
    useMesh={true}
    legends={[
      {
        anchor: 'bottom-right',
        direction: 'column',
        justify: false,
        translateX: 100,
        translateY: 0,
        itemsSpacing: 0,
        itemDirection: 'left-to-right',
        itemWidth: 80,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: 'circle',
        symbolBorderColor: 'rgba(0, 0, 0, .5)',
        effects: [
          {
            on: 'hover',
            style: {
              itemBackground: 'rgba(0, 0, 0, .03)',
              itemOpacity: 1
            }
          }
        ]
      }
    ]}
  />
  </div>
)




export default GraphInventoryComponent;
