import { useEffect, useState } from "react";
import WalletDetails from "../../components/supervisor/WalletComponents/WalletDetails";
import "./WalletPage.scss";
import { getAllWalletInfo } from "../../services/utilsWallet";
import WalletCustomerInfo from "../../components/supervisor/WalletComponents/WalletCustomerInfo";

const WalletPage = () => {
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [walletInfo, setWalletInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [recharge,setRechage] = useState(false);
  const [state, setState] = useState(0);

  useEffect(() => {
    let fkOrganization = localStorage.getItem("fk_organization");
    console.log(fkOrganization);
    if (selectedWallet != null) {
      console.log("selectedWallet", selectedWallet);
      getAllWalletInfo(
        fkOrganization,
        1,
        5,
        selectedWallet.wallet_type,
        selectedWallet.id
      ).then((data) => {
        setWalletInfo(data);
        console.log(data);
      });
    } else {
      setLoading(true);
    }
  }, [selectedWallet]);

  useEffect(() => {
    console.log("ahhh");
    if (state === 1) {
      setLoading(false);
    }
    setState(1);
    // setLoading(false);
  }, [walletInfo]);

  return (
    <div className="walletsMain">
      <div className="columnWalletDetails">
        <WalletDetails
          setSelectedWallet={setSelectedWallet}
          selectedWallet={selectedWallet}
          walletInfo={walletInfo}
          setWalletInfo={setWalletInfo}
          loading={loading}
          setLoading={setLoading}
          recharge={recharge}
          setRechage={setRechage}
        ></WalletDetails>
      </div>
      <div className="columnWalletMovements">
        {!loading &&(
          <WalletCustomerInfo
            walletInfo={walletInfo}
            setWalletInfo={setWalletInfo}
            selectedWallet={selectedWallet}
          ></WalletCustomerInfo>
        )}
      </div>
    </div>
  );
};

export default WalletPage;
