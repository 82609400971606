// import "./ResumeProductInventoryDetail.scss";
import React, { useEffect } from "react";
import "./WalletCustomerInfo.scss";
import Pagination from "@mui/material/Pagination";
import { useState } from "react";
import { getAllWalletInfo } from "../../../services/utilsWallet";
import DefaultImg from "../../../assets/market-icons/avatar-supervisor.jpg";
import WalletIcon from "@mui/icons-material/Wallet";

const WalletCustomerInfo = (props) => {
  const { walletInfo, setWalletInfo, selectedWallet } = props;

  const [currentPage, setCurrentPage] = useState(1);

  const [movements, setMovements] = useState([]);

  const formatMoney = (number) => {
    return new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
      minimumFractionDigits: 0,
    maximumFractionDigits: 0
    }).format(number);
  }

  const handlePageChange = (event, newPage) => {
    let fkOrganization = localStorage.getItem("fk_organization");
    setCurrentPage(newPage);
    getAllWalletInfo(
      fkOrganization,
      newPage,
      5,
      selectedWallet.wallet_type,
      selectedWallet.id
    ).then((data) => {
      setMovements(data.wallet_movements);
      // console.log(data);
    });
  };

  useEffect(() => {
    setMovements(walletInfo.wallet_movements);
  }, [walletInfo]);

  useEffect(() => {
    console.log(movements);
  }, [movements]);
  return (
    <div className="wallet-customer-info">
      <li className="wallet-mov-text">Movimientos de Billetera</li>
      <div>
        {movements.map((movement) => (
          <div>
            {movement.type === "transaction" ? (
              <div className="row-wallet-information">
                <div className="row-wallet-information-rechage">
                  <li>{movement.datetime}</li>
                  <div className="row-info-transaction">
                    <div className="row-wallet-information-image">
                      <img
                        src={movement.customer.image || DefaultImg}
                        alt=""
                        className="image-wallet-balance"
                        onError={(e) => {
                          e.target.src = DefaultImg;
                        }}
                      />
                    </div>
                    <div className="row-wallet-information-text">
                      <li className="li-name-wallet">{movement.customer.name}</li>
                      <div>
                        <li>
                          Compra - {movement.organization.sale_point_type.name}
                        </li>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row-wallet-information-amount">
                  <li>{formatMoney(movement.transaction_value / 100)}</li>
                </div>
              </div>
            ) : (
              <div className="row-wallet-information">
                <div className="row-wallet-information-rechage">
                  <li>{movement.datetime}</li>
                  <div className="row-wallet-information-image-pay-in">
                    <div className="bachground-payin">
                      <WalletIcon className="icon-recharge"></WalletIcon>
                    </div>
                    <li className="rechage-payin-text">Recarga</li>
                  </div>
                </div>
                <div className="row-wallet-information-refund">
                  <li>{formatMoney(movement.amount / 100)}</li>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="wallet-movements-paginator">
        <Pagination
          count={walletInfo.pagination.num_pages}
          color="primary"
          page={currentPage}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default WalletCustomerInfo;
