//Dependencias
import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { ThemeProvider } from "@material-ui/styles";

import { createTheme } from "@material-ui/core/styles";
import Context, { UserContextProvider } from "./context/UserContext";
import useUser from "./services/usersManage/useUser";
import { Redirect } from "react-router";
//Archivos de ayuda
import { LOCALES } from "./i18n/locales";
import messages from "./i18n/messages";
//Componentes
import { LogIn } from "./pages/loginPage/Login";
import Navbar from "./components/supervisor/vertialmenu/NavBar";
import { NavBarProfile } from "./components/supervisor/navBarPorfile/navBarProfile";

//Franchise
import { SalePointForm } from "./pages/Franchise/FranchiseForm/SalePointForm";
import MyFranchisesPage from "./pages/BigPages/MyFranchisesPage";
//Operators
import WorkerPage from "./pages/Operator/WorkerPage/WorkerPage";
import OperatorDetail from "./pages/Operator/OperatorDetail/OperatorDetail";
import WorkerForm from "./pages/Operator/WorkerForm/WorkerForm";
//Products
import MyProductPage from "./pages/BigPages/MyProductPage";
import ProductListPage from "./pages/Product/ProductListPage/ProductListPage";
import ProductForm from "./pages/Product/ProductForm/ProductForm";

import "bootstrap/dist/css/bootstrap.min.css";
import Subscribe from "./pages/Subscribe/Subscribe";
import { IngredientForm } from "./pages/Ingredient/IngredientForm/IngredientForm";
import { ProductCategoryForm } from "./pages/ProductCategory/ProductCategoryForm/ProductCategoryForm";
import { FranchiseTypeForm } from "./pages/FranchiseType/FranchiseTypeForm/FranchiseTypeForm";
import CredentialsPage from "./pages/Authentication/CredentialsPage/CredentialsPage";

//Home
import HomePage from "./pages/HomePage/HomePage";

//Invoice Schema
import InvoiceSchemaForm from "./pages/Invoice Schemas/InvoiceSchemaForm/InvoiceSchemaForm";
//Reports
import ReportPage from "./pages/Reports/ReportPage";
//Profile
import Profile from "./pages/Profile/Profile";
//SUPERADMIN
/**Company */
import { CompanyForm } from "./pages/Company/CompanyForm/CompanyForm";
import SupervisorListPage from "./pages/Supervisor/SupervisorListPage/SupervisorListPage";
import SupervisorForm from "./pages/Supervisor/SupervisorForm/SupervisorForm";
//Demo
import { DemoMain } from "./pages/Demo/DemoMain";
import CompanyList from "./pages/Company/CompanyList/CompanyList";
/**
 * Suspension
 */
import { SuspensionPage } from "./pages/SuspensionPage/SuspensionPage";
/**
 * Inventory page
 * @returns
 */
import InventoryPage from "./pages/Inventory/InventoryPage";
import ProductInventory from "./pages/Inventory/ProductInventory/ProductInventory";
/**
 * CashCount
 * @returns
 */
import CashCount from "./pages/CashCount/CashCount";
import SalesBalance from "./pages/SalesBalance/SalesBalance";

/**
 * Stock Page
 * @returns
 */
import StockPage from "./pages/StockPage/StockPage";
import CritialProviderPage from "./pages/StockPage/CritialProviderPage/CritialProviderPage";
import SalePointTypeCard from "./components/supervisor/SalePointTypeCard/SalePointTypeCard";
import SalePointTypeForm from "./pages/SalePointType/SalePointTypeForm/SalePointTypeForm";
import SalePointTypeEdit from "./pages/SalePointType/SalePointTypeEdit/SalePointTypeEdit";
import SalePointTypeContext from "./context/StoreIdContext";
import HomeMarket from "./pages/HomePage/HomeMarket";
import OrganizationList from "./pages/Organization/OrganizationList/OrganizationList";
import OrganizationForm from "./pages/Organization/OrganizationForm/OrganizationForm";
import InventoryHome from "./pages/Inventory/InventoryHome/InventoryHome";
import WalletPage from "./pages/WalletPage/WalletPage";
import DashboardSales from "./pages/Dashboard/Dashboard Sales/DashboardSales";
import TagsListPage from "./pages/TagsPage/TagsListPage/TagsListPage";
import TagsPageManagement from "./pages/TagsPage/TagsPageManagement/TagsPageManagement";

//DEPENDENCES TRY
function Routes() {
  const private_paths = [
    "/home",
    "/puntos-de-venta",
    "/puntos-de-venta/:id",
    "/puntos-de-venta/:id/edit",
    "/puntos-de-venta/create",
    "/trabajadores",
    "/trabajadores/:id",
    "/trabajadores/:id/edit",
    "/trabajadores/create",
    "/productos",
    "/productos/:id",
    "/productos/:id/edit",
    "/productos/create",
    "/promociones",
    "/resoluciones-facturacion/create",
    "/resoluciones-facturacion/edit",
    "/subscribe",
    "/subscribe",
    "/ingredientes/:id/edit",
    "/ingredientes/create",
    "/informes",
    "/credenciales/:id",
    "perfil/:id",
    "/demo",
    "/inventario",
  ];
  const mystyle = {
    display: "flex",
    overflowX: "hidden",
  };
  const mybodyStyle = {
    backgroundColor: "#F3F3F3",
    width: "100%",
    
  };

  const [language, setLanguage] = useState(LOCALES.SPANISH);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#0070A1",
      },
      secondary: {
        main: "#00A1D1",
      },
    },
  });
  // let isLogged = false;
  const { isLogged } = useUser();

  const [currentSalePointTypeId, setCurrentSalePointTypeId] = useState();

  const { userRole, demoProgress, suspensionCompany } = useContext(Context);
  const [roleModule, setRoleModule] = useState("0");

  useEffect(() => {
    if (isLogged && userRole === "1") {
      console.log("SUPERVISOR");
      setRoleModule("1");
    } else if (isLogged && userRole === "2") {
      setRoleModule("2");
    } else {
      setRoleModule("0");
    }
  }, [userRole, isLogged]);

  let verifyPath = () => {
    if (private_paths.includes(window.location.pathname) && !isLogged) {
      window.location.pathname = "/";
      return <LogIn />;
    } else if (window.location.pathname.includes("credenciales")) {
      return <CredentialsPage />;
    } else if (
      private_paths.includes(window.location.pathname) === false &&
      window.location.pathname !== "/"
    ) {
      return 404;
    }
  };

  let checkDemo = () => {
    console.log("Check demo");
    if (isLogged) {
      console.log("is logges", demoProgress);
      if (demoProgress === "1" || demoProgress === "2") {
        console.log("CHECK DEMO", demoProgress);
        //window.location.pathname = "/";
        return <DemoMain />;
      }
    }
  };

  let checkSuspension = () => {
    console.log("Check demo");
    if (isLogged) {
      console.log("is logges", demoProgress);
      if (
        suspensionCompany === "1" ||
        suspensionCompany === "2" ||
        suspensionCompany === "3"
      ) {
        if (suspensionCompany != 0) {
          //window.location.pathname = "/";
          return <SuspensionPage />;
        }
      }
    } else {
      return <HomePage />;
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <IntlProvider locale={language} messages={messages[language]}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/">
              {roleModule === "1" ? (
                <Redirect to="/home" />
              ) : roleModule === "2" ? (
                <Redirect to="/companias" />
              ) : (
                <LogIn />
              )}
            </Route>
            {verifyPath()}
            <Route exact path="/subscribe" component={Subscribe}></Route>
            {checkDemo()}
            <Route path="/demo" exact component={DemoMain} />
            {checkSuspension()}
            <Route path="/suspension" exact component={SuspensionPage} />
            <div style={mystyle}>
              <Navbar />
              <div style={mybodyStyle}>
                <NavBarProfile />

                {/** DASHBOARDS*/}
                <Route
                  path="/dashboard-sales"
                  exact
                  component={DashboardSales}
                />
                <SalePointTypeContext.Provider
                  value={{ currentSalePointTypeId, setCurrentSalePointTypeId }}
                >

                  <Route path="/pasillos" exact component={MyFranchisesPage} />
                  <Route
                    path="/pasillos/create"
                    exact
                  >
                   <SalePointTypeForm/>
                  </Route>
                  <Route
                    path="/pasillos/:id/edit"
                    exact
                    component={SalePointTypeEdit}
                  />
                </SalePointTypeContext.Provider>
                <Route
                  path="/puntos-de-venta/create"
                  exact
                  component={SalePointForm}
                />
                <Route
                  path="/etiquetas"
                  exact
                  component={TagsListPage}
                />
                <Route
                  path="/manejo-etiquetas/:id"
                  exact
                  component={TagsPageManagement}
                />
                <Route
                  path="/puntos-de-venta/:id/edit"
                  exact
                  component={SalePointForm}
                />

                <Route path="/trabajadores" exact component={WorkerPage} />
                <Route
                  path="/trabajadores/create"
                  exact
                  component={WorkerForm}
                />
              
                <Route
                  path="/trabajadores/:id/edit"
                  exact
                  component={WorkerForm}
                />

                <Route
                  path="/ingredientes/:id/edit"
                  exact
                  component={IngredientForm}
                />
                <Route
                  path="/ingredientes/create"
                  exact
                  component={IngredientForm}
                />

                <Route
                  path="/categorias-de-producto/:id/edit"
                  exact
                  component={ProductCategoryForm}
                />
                <Route
                  path="/categorias-de-producto/create"
                  exact
                  component={ProductCategoryForm}
                />

                <Route
                  path="/tipos-de-punto-de-venta/:id/edit"
                  exact
                  component={FranchiseTypeForm}
                />
                <Route
                  path="/tipos-de-punto-de-venta/create"
                  exact
                  component={FranchiseTypeForm}
                />

                <Route path="/perfil/:id" exact component={Profile} />

                <Route path="/productos" exact component={MyProductPage} />
                <Route path="/productos/create" exact component={ProductForm} />
                <Route
                  path="/productos/:id/edit"
                  exact
                  component={ProductForm}
                />
                <Route path="/home" exact component={HomeMarket} />

                <Route
                  path="/resoluciones-facturacion/create"
                  exact
                  component={InvoiceSchemaForm}
                />
                <Route
                  path="/resoluciones-facturacion/:id/edit"
                  exact
                  component={InvoiceSchemaForm}
                />

                <Route path="/informes" exact component={ReportPage} />

                <Route
                  path="/credenciales/:id"
                  exact
                  component={CredentialsPage}
                />
                <Route path="/inventarios" exact component={InventoryHome} />
                <Route
                  path="/inventarios/:id"
                  exact
                  component={ProductInventory}
                />
                <Route path="/efectivo" exact component={CashCount} />
                <Route path="/ventas" exact component={SalesBalance} />
                <Route path="/stock" exact component={CritialProviderPage} />
                <Route path="/billeteras" exact component={WalletPage} />

                {/**SUPERADMIN */}
                <Route path="/organizaciones" exact component={OrganizationList} />
                <Route path="/organizaciones/create" exact component={OrganizationForm} />
                
                <Route
                  path="/organizaciones/:id/edit"
                  exact
                  component={OrganizationForm}
                />

                <Route path="/companias/create" exact component={CompanyForm} />
                
                <Route
                  path="/companias/:id/edit"
                  exact
                  component={CompanyForm}
                />
                <Route
                  path="/supervisores"
                  exact
                  component={SupervisorListPage}
                />
                <Route
                  path="/supervisores/create"
                  exact
                  component={SupervisorForm}
                />
                <Route
                  path="/supervisores/:id/edit"
                  exact
                  component={SupervisorForm}
                />
              </div>
            </div>
          </Switch>
        </BrowserRouter>
      </IntlProvider>
    </ThemeProvider>
  );
}

export default Routes;
