import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import { TextField, Snackbar } from "@material-ui/core";
import { getFranchiseType, postFranchiseType, putFranchiseType } from "../../../services/utilsFranchiseType/utilsFranchiseType";
import "./FranchiseTypeForm.scss"; import CustomColorPicker from "../../../components/supervisor/ColorPicker/ColorPicker";
import RectangularImagePicker from "../../../components/supervisor/RectangularImagePicker/RectangularImagePicker";
import FranchiseTypeDefaultImg from "../../../assets/icons/DEFAULT IMG/Franchise_type_img.jpg"
import { Alert } from "@mui/material";
import { useHistory } from "react-router-dom";

export const FranchiseTypeForm = () => {
  // ----------------------------------------------------
  // ------------- Variables & Constants ----------------
  // ----------------------------------------------------

  const [loading, setLoading] = useState(true);

  const [formTitle, setFormTitle] = useState("createFranchiseType");

  const [submitLabel, setSubmitLabel] = useState("createFranchiseTypeLbl");

  const [submitted, setSubmitted] = useState(false)

  const [franchiseType, setFranchiseType] = useState({
    color_primary: "",
    color_secondary: "",
    color_primary_light: "",
    color_secondary_light: "",
    color_primary_dark: "",
    color_secondary_dark: "",
    principal_image_url: "",
    toolbar_image_url: "",
    invoice_image_url: "",
    principal_image: "",
    toolbar_image: "",
    invoice_image: "",
  });

  const urlLocation = useLocation();

  const labelConverter = (name) => {
    const defaultLbl = "no label";
    const labels = {
      color_primary: "primaryColorLbl",
      color_primary_light: "lightPrimaryColorLbl",
      color_primary_dark: "darkPrimaryColorLbl",
      color_secondary: "secondaryColorLbl",
      color_secondary_light: "lightSecondaryColorLbl",
      color_secondary_dark: "darkSecondaryColorLbl",
      principal_image_url: "principalImageLbl",
      toolbar_image_url: "toolbarImageLbl",
      invoice_image_url: "receiptImageLbl",
    }
    return labels[name] || defaultLbl
  }

  const [snackbarMessage, setSnackbarMessage] = useState(<Alert severity="success">NONE</Alert>)

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [successSnackbar, setSuccessSnackbar] = useState(false)

  const history = useHistory();

  // ----------------------------------------------------
  // ------------------ Async calls ---------------------
  // ----------------------------------------------------

  const fetchData = async () => {

    if (urlLocation.pathname.includes("edit")) {
      let franchiseTypeId = localStorage.getItem("fk_franchise_type");
      setFormTitle("editFranchiseType");
      setSubmitLabel("saveFranchiseTypeLbl");

      getFranchiseType(franchiseTypeId).then((data) => {
        console.log(data);
        let franchiseTypeData = { ...franchiseType, ...data.franchise_type }
        let franchiseTypeObject = {
          ...franchiseTypeData,
          principal_image_url: franchiseTypeData.principal_image,
          toolbar_image_url: franchiseTypeData.toolbar_image,
          invoice_image_url: franchiseTypeData.invoice_image,
        };
        console.log(franchiseTypeObject)
        setFranchiseType(franchiseTypeObject)
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData().then(() => { });
  }, []);

  // ----------------------------------------------------
  // ------------------- Handlers -----------------------
  // ----------------------------------------------------

  const handleInputChangeInner = (event) => {
    setFranchiseType({ ...franchiseType, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true)
    console.log(franchiseType)
    let formDataBody = new FormData();
    for (const key in franchiseType) {

      if (typeof franchiseType[key] == "object" && franchiseType[key] && key !== "franchise_type") {
        console.log("semos file yess", key, franchiseType[key])
        formDataBody.append(key, franchiseType[key], `${key}.jpg`);
      } else {
        formDataBody.append(key, franchiseType[key]);
      }
    }
    if (urlLocation.pathname.includes("edit")) {
      formDataBody.append("fk_franchise_type", franchiseType.id);
      putFranchiseType(formDataBody).then((data) => {
        if (data.error === undefined) {
          setSuccessSnackbar(true)
          setSnackbarMessage(<Alert severity="success">Se ha actualizado el pasillo con éxito</Alert>);
          setOpenSnackbar(true);
        } else {
          setSuccessSnackbar(false)
          setSnackbarMessage(<Alert severity="error">Error: {data.error}</Alert>);
          setOpenSnackbar(true);
        }
        setSubmitted(false)
      });

      console.log(formDataBody)
    } else {
      let supervisorId = localStorage.getItem("fk_supervisor")
      formDataBody.append("fk_supervisor", supervisorId)
      postFranchiseType(formDataBody).then((data) => {
        if (data.error === undefined) {
          setSuccessSnackbar(true)
          setSnackbarMessage(<Alert severity="success">Se ha creado el pasillo con éxito</Alert>);
          setOpenSnackbar(true);
        } else {
          setSuccessSnackbar(false)
          setSnackbarMessage(<Alert severity="error">Error: {data.error}</Alert>);
          setOpenSnackbar(true);
        }
        setSubmitted(false)
      })
    }
  };

  const handleColorChange = (color, keyName) => {
    console.log(keyName, color)
    setFranchiseType({ ...franchiseType, [keyName]: color })

  }

  const handleOnImageChange = (url, file, keyName) => {
    console.log(url, typeof file, keyName)
    let updatedFranchiseType = {
      ...franchiseType,
      [keyName]: file,
      [`${keyName}_url`]: url
    }
    setFranchiseType(updatedFranchiseType)
    console.log(franchiseType)
  }

  const checkKeyDown = (event) => {
    if (event.code === "Enter") event.preventDefault();
  };

  const handleSnackbarClose = (event, reason, success = false) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
    if (successSnackbar) {
      history.push("/puntos-de-venta");
    }
  };

  // ----------------------------------------------------
  // -------------------- Render ------------------------
  // ----------------------------------------------------
  return (
    <main className="section-body row">
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}

      >
        {snackbarMessage}
      </Snackbar>
      <div className="col-6">
        <h1 className="section-title">
          <FormattedMessage id={formTitle} />
        </h1>
        {!loading && (
          <form
            onSubmit={handleSubmit}
            className="row g-3"
            onKeyDown={(e) => checkKeyDown(e)}
          >
            <div className="col-6 mb-3">
              <TextField
                required
                className="form-control"
                id="standard-basic"
                label={<FormattedMessage id="nameLbl" />}
                value={franchiseType.name ? franchiseType.name : ""}
                name="name"
                onChange={handleInputChangeInner}
              />
            </div>
            <div className="mb-4 description-container">
              <TextField
                required
                className="form-control"
                id="standard-basic"
                label={<FormattedMessage id="descriptionLbl" />}
                value={franchiseType.description ? franchiseType.description : ""}
                name="description"
                onChange={handleInputChangeInner}
                rows={4}
                multiline
              />
            </div>
            <div className="row">
              {Object.keys(franchiseType)
                .filter((keyName) => keyName.includes("color"))
                .map((keyName, index) => {
                  return (
                    <div className="col-6 mb-2">
                      <CustomColorPicker
                        key={index}
                        color={franchiseType[keyName]}
                        handleColorChange={handleColorChange}
                        label={labelConverter(keyName)}
                        keyName={keyName}
                      />
                    </div>
                  );
                })}
            </div>
            {Object.keys(franchiseType)
              .filter((keyName) => keyName.includes("url"))
              .map((keyName, index) => {
                return (
                  <>
                    {index === 1 && <div className="col-6"></div>}
                    <div className="col-6 mt-3">
                      <RectangularImagePicker
                        key={index}
                        img={franchiseType[keyName] || FranchiseTypeDefaultImg}
                        label={labelConverter(keyName)}
                        handleOnImageChange={handleOnImageChange}
                        keyName={keyName.slice(0, -4)}
                      />
                    </div>
                  </>
                );
              })}
            <div className="row mt-5">
              <div className="col-6"></div>
              <div className="col-6 d-flex justify-content-end">
                <Link className="" to={`/puntos-de-venta`}>
                  <button className="btn btn-outline-primary m-2">
                    <FormattedMessage id="cancelLbl" />
                  </button>
                </Link>
                <button
                  className="btn btn-primary m-2"
                  type="submit"
                  disabled={submitted}
                >
                  {submitted && (
                    <div className="d-flex justify-content-center spinner-container">
                      <div
                        className="spinner-border spinner-primary"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}

                  <FormattedMessage id={submitLabel} />
                </button>
              </div>
            </div>
          </form>
        )}
        {loading && (
          <div className="d-flex justify-content-center spinner-container">
            <div className="spinner-border spinner-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
      </div>
      <div className="col-6">
        HOla
      </div>
    </main>
  );
};
