import {ResponsiveBar} from '@nivo/bar'
import "./SalesBalance.css"
import React, {useEffect, useState} from "react";
import {MenuItem, Select} from "@material-ui/core";
import {FormattedMessage} from "react-intl";
import Chip from "@material-ui/core/Chip";
import RemoveIcon from "@material-ui/icons/RemoveCircleOutlineSharp";
import TextField from "@material-ui/core/TextField";

import Autocomplete from "@material-ui/lab/Autocomplete";
import {getSalePointsOfOrganization} from "../../services/utilsFranchise/utilsFranchise";
import {DateRangePickerComponent} from "@syncfusion/ej2-react-calendars";
import {getSalesHistogram} from "../../services/utilsInformation/utilsInformation";
import LinearProgress from "@material-ui/core/LinearProgress";


const SalesBalance = () =>{

    /**
     *
     */

    const [franchises, setFranchises] = useState([]);

    const [franchiseList, setFranchiseList] = useState([]);

    const [typeOfRangeSelected, setTypeOfRangeSelected] = useState(1);

    const [oneDaySelected, setOneDaySelected] = useState(new Date().toISOString().substr(0,10));

    const [rangeDatesSelected, setRangeDatesSelected] = useState([]);

    const [seeWatchShow, setWatchSeeShow] = useState(2);

    const [loading, setLoading] = useState(true);

    const [graphType, setGraphType] = useState("hour");

    const [initialState, setInitialState] = useState(true);

    const [data, setData] = useState([]);


    let months = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septimebre",
        "Octubre",
        "Noviembre",
        "Diciembre",
    ];

    let days = ["Lun", "Mar", "Mier", "Jue", "Vier", "Sab", "Dom"];


    const startValue = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        14
    );
    const endValue = new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        -15
    );
    const handleOneDaySelected = (event) => {
        setOneDaySelected(event.target.value);
    };

    let formatHourAMPM = (time) => {
        let hours = time.split(":")[0];
        let ampm = hours >= 12 ? "pm" : "am";
        hours = hours % 12;
        hours = hours ? hours : 12;
        let strTime = hours + " " + ampm;
        return strTime;
    };

    const handleTypeRange = (event) => {
        //setTableLoading(true);
        setTypeOfRangeSelected(event.target.value);
    };

    const franchiseHTML = franchises.map((option, index) => {
        // This is to handle new options added by the user (allowed by freeSolo prop).
        const label = option.name || option;
        return (
            <Chip
                key={label}
                label={label}
                className="chip-margin"
                deleteIcon={<RemoveIcon />}
                onDelete={() => {
                    setFranchises(franchises.filter(entry => entry !== option));
                }}
            />
        );

    });

    let getFormattedDate = (date) => {
        console.log(date);
        return date.toISOString().split("T")[0];
    };

    let handleFranchiseSelected  = (value) =>{
        let currentFranchises = franchises.filter((value2) => value === value2 );
        if(currentFranchises.length === 0)
            setFranchises(value);
       // setFranchiseList(franchiseList.filter(value2 => value !== value2 ));
    }




    let keys = franchises.map((franchise) => franchise.name);


    let infoFormatted = (data) =>{
        console.log(data)
        return data['sales_histogram'].map((info) => {
            let keyName = info.counts.franchise_name

            if (typeOfRangeSelected === 1) {
                let hourVar = info.hour.split("T")[1];
                return {"hour": formatHourAMPM(hourVar), [keyName]: info.counts.total_sales}
            } else if (typeOfRangeSelected === 2) {
                let dateValue = info.day.split("T")[0];
                let dateObj = new Date(dateValue);
                return {"day": (days[dateObj.getDay()] + "-" + dateObj.getDate()), [keyName]: info.counts.total_sales}
            } else if (typeOfRangeSelected === 3) {
                return {"month": (months[info.month - 1]), [keyName]: info.counts.total_sales}
            }else{
                return{};
            }
        });
    }


    const fetchData = () =>{
        console.log(oneDaySelected, "one day salected");
        if(franchises.length ===0){

            getSalePointsOfOrganization().then((data) => {
                setFranchiseList(data['franchises']);
                setFranchises([data['franchises'][0]])

                getSalesHistogram(data['franchises'][0].id,oneDaySelected, oneDaySelected).then(
                    (data) =>{
                        let dataTransformed = data['sales_histogram'].map( (info) =>{
                            console.log(info.hour)
                            let hourVar = info.hour.split("T")[1];
                            let keyName = info.counts.franchise_name
                            return {"hour": formatHourAMPM(hourVar), [keyName]: info.counts.total_sales}
                            });
                        setData(dataTransformed);

                        setLoading(false);
                        console.log(dataTransformed)
                        setInitialState(false);

                    }
                )
            });
        }

    }

    useEffect(() =>{
        fetchData();
    }, []);

    const submitNewHistogram = (event)=> {
        event.preventDefault()
        setLoading(true);

        let startDate, endDate;

        if(typeOfRangeSelected ===1 ){
            startDate = oneDaySelected;
            endDate = oneDaySelected;

        }else{
            startDate = getFormattedDate(rangeDatesSelected[0]);
            endDate = getFormattedDate(rangeDatesSelected[1]);
        }

        if(typeOfRangeSelected === 1){
            setGraphType("hour");
        } else if( typeOfRangeSelected === 2){
            setGraphType("day");
        } else if(typeOfRangeSelected === 3){
            setGraphType("month");
        }

        if(franchises.length >1)
        {

            let provitionalData  = [];

            /*
            for (let i = 0; i < franchises.length; i++) {
                let franchise = franchises[i];
                getSalesHistogram(franchise.id, startDate, endDate).then(
                    // eslint-disable-next-line no-loop-func
                    (response) =>{
                        if(i === 0){
                            let formattedData  = infoFormatted(response);
                            console.log("Entro a index 0", i)
                            setData(formattedData)
                        }else{
                            let newData = []
                            data.forEach(
                                (dataItem, index) =>{

                                    dataItem[franchise.name] = response['sales_histogram'][index].counts.total_sales
                                    newData.push(dataItem);
                                }
                            )
                            console.log(newData, "This is new data");
                            console.log("Entro a index +0", i)
                            setData(newData);
                        }
                        if(franchises.length-1 === i){

                            setLoading(false);

                        }
                    }
                )

            }
            */

            franchises.forEach(
                (franchise, index) => getSalesHistogram(franchise.id, startDate, endDate).then(
                    (response) =>{
                        if(index === 0){
                            let formattedData  = infoFormatted(response);
                            console.log("Entro a index 0", index)

                        }else{
                            let newData = []
                            data.forEach(
                                (dataItem, index) =>{
                                    dataItem[franchise.name] = response['sales_histogram'][index].counts.total_sales
                                    newData.push(dataItem);
                                }
                            )
                           
                            setData(newData);
                        }
                        if(franchises.length-1 === index){

                            setLoading(false);

                        }
                    }
                )
            )
        }else {
            let fk_franchise = franchises[0].id;
            getSalesHistogram(fk_franchise, startDate, endDate).then((response) => {
                let newData = infoFormatted(response);
                setData(infoFormatted(response));
                setLoading(false);
            })
        }

    }
    return (
        <main className="section-body">
            <h1 className="section-title">Balance de ventas</h1>
            <form action="" onSubmit={submitNewHistogram}>
                <div className="row">
                    <div className="col-6">
                        <div className="row">
                            <div className="col-6">
                                <label className="form-label" htmlFor="franchiseInput">
                                    <FormattedMessage id="indicatorLbl"/>
                                </label>
                                <Select
                                    required
                                    disabled={loading}
                                    value={seeWatchShow}
                                    name="What to show"
                                    onChange={(event => setWatchSeeShow(event.target.value))}
                                    className="worker-form-select"
                                    id="franchiseInput"
                                >
                                    <MenuItem key={2} value={2}>
                                        Total ventas
                                    </MenuItem>
                                    <MenuItem key={1} value={1}>
                                        Numero de ventas
                                    </MenuItem>
                                </Select>
                            </div>
                            <div className="col-6">
                                <label className="form-label" htmlFor="franchiseInput">
                                    <FormattedMessage id="franchiseLbl"/>
                                </label>
                                <Autocomplete
                                    multiple
                                    id="tags-standard"
                                    disabled={loading}
                                    freeSolo
                                    required
                                    filterSelectedOptions
                                    options={franchiseList}
                                    onChange={(e, newValue) => handleFranchiseSelected(newValue)}
                                    getOptionLabel={option => option.name}
                                    renderTags={() => {}}
                                    value={franchises}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            placeholder="Fraquicias"
                                            margin="normal"
                                            className="autocomplete-size"
                                            fullWidth
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="selectedTags">{franchiseHTML}</div>

                    </div>
                    <div className="col-6">

                        <div>
                            <label className="form-label" htmlFor="franchiseInput">
                                <FormattedMessage id="typeOfRange"/>
                            </label>
                            <br/>
                            <Select
                                required
                                disabled={loading}
                                value={typeOfRangeSelected}
                                name="fk_franchise"
                                onChange={handleTypeRange}
                                className="worker-form-select"
                                id="franchiseInput"
                            >
                                <MenuItem key={1} value={1}>
                                    Dia
                                </MenuItem>
                                <MenuItem key={2} value={2}>
                                    Rango de dias (Max. 45 dias)
                                </MenuItem>
                                <MenuItem key={3} value={3}>
                                    Rango de meses
                                </MenuItem>
                            </Select>
                        </div>
                        {typeOfRangeSelected === 1 && (
                            <div className="container-input row">
                                <label
                                    className="form-label no-padding-6"
                                    htmlFor="franchiseInput"
                                >
                                    <FormattedMessage id="finalDateLbl"/>
                                </label>
                                <input
                                    required
                                    disabled={loading}
                                    type="date"
                                    value={oneDaySelected}
                                    name="end_date"
                                    onChange={handleOneDaySelected}
                                    className="worker-form-select"
                                />
                            </div>
                        )}
                        {typeOfRangeSelected === 2 && (
                            <div className="range-date-container">
                                <label className="form-label" htmlFor="franchiseInput">
                                    <FormattedMessage id="selectRangeDate"/>
                                </label>
                                <DateRangePickerComponent
                                    disabled={loading}
                                    placeholder="Enter Date Range"
                                    startDate={startValue}
                                    endDate={endValue}
                                    minDays={2}
                                    maxDays={45}
                                    format="dd-MMM-yy"
                                    onChange={(date) => {
                                        console.log(date.value);
                                        setRangeDatesSelected(date.value);
                                    }}
                                />
                            </div>

                        )}
                        {typeOfRangeSelected === 3 && (
                            <div className="range-date-container">
                                <label className="form-label" htmlFor="franchiseInput">
                                    <FormattedMessage id="selectRangeDate"/>
                                </label>
                                <DateRangePickerComponent
                                    disabled={loading}
                                    placeholder="Enter Date Range"
                                    startDate={startValue}
                                    endDate={endValue}
                                    minDays={30}
                                    maxDays={356}
                                    format="dd-MMM-yy"
                                    onChange={(date) => {
                                        setRangeDatesSelected(date.value);
                                    }}
                                    //Uncomment below code to show month range picker. Also comment the properties min, max, mindays and maxdays
                                    start="Year"
                                    depth="Year"
                                />
                            </div>
                        )}
                    </div>
                </div>

                <div className="">

                    { !loading && (<button className="btn btn-primary m-2" type="submit">
                        CREAR NUEVO HISTOGRAMA
                    </button>)}
                </div>
            </form>

            <div className="chart-size">
                {loading && <LinearProgress/>}

                {data !== []  && !loading && (

                    <MyResponsiveBar data={data} keys={keys} type={graphType}/>
                )}
            </div>

        </main>
    )
}

export default SalesBalance;

const MyResponsiveBar = ({ data /* see data tab */ , keys, type}) => (
    <ResponsiveBar
        data={data}
        keys={keys}
        indexBy={type}
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        padding={0.3}
        groupMode="grouped"
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={{ scheme: 'nivo' }}

        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Tiempo',
            legendPosition: 'middle',
            legendOffset: 32
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Valor',
            legendPosition: 'middle',
            legendOffset: -40
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        legends={[
            {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
        role="application"
        ariaLabel="Nivo bar chart demo"
        barAriaLabel={function (e){return e.id+": "+e.formattedValue+" in country: "+e.indexValue}}
    />
)