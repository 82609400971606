import React, {
  Component,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import "./NavBar.scss";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import Logo from "../../../assets/icons/mkt-logo-blanco.svg";
import foodHouseicon from "../../../assets/icons/food_house-24px.svg";
import groupIcon from "../../../assets/icons/group-24px.svg";
import foodIcon from "../../../assets/icons/fastfood-24px.svg";
import cashIcon from "../../../assets/icons/local_atm_black_24dp.svg";
import { FormattedMessage } from "react-intl";
import useUser from "../../../services/usersManage/useUser";
import Context from "../../../context/UserContext";
import { any } from "joi";
import MenuIcon from "@mui/icons-material/Menu";
import { Icon } from "@mui/material";
import WalletIcon from "@mui/icons-material/Wallet";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import DashboardIcon from "@mui/icons-material/Dashboard";
import LineAxisIcon from "@mui/icons-material/LineAxis";
import StyleIcon from '@mui/icons-material/Style';

export const Navbar = () => {
  const { isLogged, logout } = useUser();
  const { userRole } = useContext(Context);
  const { showNavbar, setShowNavbar } = useContext(Context);

  const handleClickLogOut = (e) => {
    setShowNavbar(false);
    logout();
  };
  const isMobile = useMediaQuery({ maxWidth: 768 });

  const handleClickOcultar = (e) => {
    setShowNavbar(false);
  };

  return (
    <div>
      {isMobile ? (
        <div>
          <div className="resposive-menu">
            {showNavbar && (
              <div className="menu_container">
                <Link to="/home" onClick={handleClickOcultar}>
                  <p aria-current="page" className="brand">
                    <img src={Logo} alt="logo_img" className="img_logo" />
                  </p>
                </Link>
                {userRole === "1" && (
                  <div>
                    {console.log("CSMNIO")}
                    {/* <h5 className="titulo-sec-nav-bar">
                      <FormattedMessage
                        className="navbar-option"
                        id="informationTitle"
                      />
                    </h5>
                    <Link
                      to="/dashboard-sales"
                      style={{ textDecoration: "none" }}
                    >
                      <a href="/dashboard-sales" className="link-nav-item">
                        <DashboardIcon className="menu-icon"></DashboardIcon>
                        <div className="text-block textovertialnavbar">
                          <FormattedMessage
                            className="navbar-option"
                            id="dashboardSalesTitle"
                          />
                        </div>
                      </a>
                    </Link>
                    <Link
                      to="/dashboard-payin"
                      style={{ textDecoration: "none" }}
                    >
                      <a href="/dashboard-payin" className="link-nav-item">
                        <LineAxisIcon className="menu-icon"></LineAxisIcon>
                        <div className="text-block textovertialnavbar">
                          <FormattedMessage
                            className="navbar-option"
                            id="dashboardRechargeTitle"
                          />
                        </div>
                      </a>
                    </Link> */}
                    <div className="gestion">
                      <h5 className="titulo-sec-nav-bar">
                        <FormattedMessage
                          className="navbar-option"
                          id="managementTitle"
                        />
                      </h5>

                      <Link
                        to="/pasillos"
                        style={{ textDecoration: "none" }}
                        onClick={handleClickOcultar}
                      >
                        <a href="/pasillos" className="link-nav-item">
                          <img
                            src={foodHouseicon}
                            alt=""
                            className="menu-icon"
                          />
                          <div className="text-block textovertialnavbar">
                            <FormattedMessage
                              className="navbar-option"
                              id="salePointTypeTitle"
                            />
                          </div>
                        </a>
                      </Link>
                      <Link
                        to="/trabajadores"
                        style={{ textDecoration: "none" }}
                        onClick={handleClickOcultar}
                      >
                        <a href="/trabajadores" className="link-nav-item">
                          <img src={groupIcon} alt="" className="menu-icon" />
                          <div className="text-block textovertialnavbar">
                            <FormattedMessage
                              className="navbar-option"
                              id="workersTitle"
                            />
                          </div>
                        </a>
                      </Link>
                      <Link
                        to="/productos"
                        style={{ textDecoration: "none" }}
                        onClick={handleClickOcultar}
                      >
                        <a href="/productos" className="link-nav-item">
                          <img src={foodIcon} alt="" className="menu-icon" />
                          <div className="text-block textovertialnavbar">
                            <FormattedMessage
                              className="navbar-option"
                              id="productsTitle"
                            />
                          </div>
                        </a>
                      </Link>

                      <Link
                        to="/inventarios"
                        style={{ textDecoration: "none" }}
                        onClick={handleClickOcultar}
                      >
                        <a href="/inventarios" className="link-nav-item">
                        <WalletIcon className="menu-icon"></WalletIcon>

                          <div className="text-block textovertialnavbar">
                            <FormattedMessage
                              className="navbar-option"
                              id="inventoryTitle"
                            />
                          </div>
                        </a>
                      </Link>
                      <Link
                        to="/billeteras"
                        style={{ textDecoration: "none" }}
                        onClick={handleClickOcultar}
                      >
                        <a href="/billeteras" className="link-nav-item">
                          <WalletIcon className="menu-icon"></WalletIcon>
                          <div className="text-block textovertialnavbar">
                            <FormattedMessage
                              className="navbar-option"
                              id="walletsTitle"
                            />
                          </div>
                        </a>
                      </Link>
                      <Link
                        to="/informes"
                        style={{ textDecoration: "none" }}
                        onClick={handleClickOcultar}
                      >
                        <a href="/informes" className="link-nav-item">
                          <img
                            src="https://uploads-ssl.webflow.com/5ee80e765cd1c56a0214b32d/5eeadd98a317b72a3e03c8bd_payment-24px.svg"
                            alt=""
                            className="menu-icon"
                          />
                          <div className="text-block textovertialnavbar">
                            <FormattedMessage
                              className="navbar-option"
                              id="operationalReportsTitle"
                            />
                          </div>
                        </a>
                      </Link>
                    </div>
                    <div className="pagos"></div>
                  </div>
                )}

                {userRole === "2" && (
                  <div className="gestion">
                    <h5 className="titulo-sec-nav-bar">
                      <FormattedMessage
                        className="navbar-option"
                        id="managementTitle"
                      />
                    </h5>
                    <Link
                      to="/organizaciones"
                      style={{ textDecoration: "none" }}
                    >
                      <a href="/organizaciones" className="link-nav-item">
                        <img src={foodHouseicon} alt="" className="menu-icon" />
                        <div className="text-block textovertialnavbar">
                          <FormattedMessage
                            className="navbar-option"
                            id="organizationTitle"
                          />
                        </div>
                      </a>
                    </Link>
                    <Link to="/supervisores" style={{ textDecoration: "none" }}>
                      <a href="/supervisores" className="link-nav-item">
                        <img src={groupIcon} alt="" className="menu-icon" />
                        <div className="text-block textovertialnavbar">
                          <FormattedMessage
                            className="navbar-option"
                            id="Supervisor"
                          />
                        </div>
                      </a>
                    </Link>
                  </div>
                )}
                <div id="logout-div">
                  <a id="logoutBtn" href=" " className="link-nav-item">
                    <img
                      src="https://uploads-ssl.webflow.com/5ee80e765cd1c56a0214b32d/5efcd3ed8bd97b2bdd208f4b_exit_to_app-white-18dp.svg"
                      alt=""
                      className="menu-icon"
                    />
                    <div className="text-block textovertialnavbar">
                      <Link
                        onClick={handleClickLogOut}
                        className="vertical-link"
                      >
                        <FormattedMessage
                          className="navbar-option"
                          id="logOutTitle"
                        />
                      </Link>
                    </div>
                  </a>
                  <div className="titulo-sec-nav-bar">v 1.3.0</div>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          data-collapse="medium"
          data-animation="default"
          data-duration="400"
          role="banner"
          className="vertical_div_menu"
          id="wrapper"
        >
          <div className="menu_container">
            <Link to="/home">
              <p aria-current="page" className="brand">
                <img src={Logo} alt="logo_img" className="img_logo" />
              </p>
            </Link>
            {userRole === "1" && (
              <div>
                <div className="gestion">
                  {/* <h5 className="titulo-sec-nav-bar">
                    <FormattedMessage
                      className="navbar-option"
                      id="informationTitle"
                    />
                  </h5>
                  <Link
                    to="/dashboard-sales"
                    style={{ textDecoration: "none" }}
                  >
                    <a href="/dashboard-sales" className="link-nav-item">
                      <DashboardIcon className="menu-icon"></DashboardIcon>
                      <div className="text-block textovertialnavbar">
                        <FormattedMessage
                          className="navbar-option"
                          id="dashboardSalesTitle"
                        />
                      </div>
                    </a>
                  </Link>
                  <Link
                    to="/dashboard-payin"
                    style={{ textDecoration: "none" }}
                  >
                    <a href="/dashboard-payin" className="link-nav-item">
                      <LineAxisIcon className="menu-icon"></LineAxisIcon>
                      <div className="text-block textovertialnavbar">
                        <FormattedMessage
                          className="navbar-option"
                          id="dashboardRechargeTitle"
                        />
                      </div>
                    </a>
                  </Link> */}
                  <h5 className="titulo-sec-nav-bar">
                    <FormattedMessage
                      className="navbar-option"
                      id="managementTitle"
                    />
                  </h5>
                  <Link to="/pasillos" style={{ textDecoration: "none" }}>
                    <a href="/pasillos" className="link-nav-item">
                      <img src={foodHouseicon} alt="" className="menu-icon" />
                      <div className="text-block textovertialnavbar">
                        <FormattedMessage
                          className="navbar-option"
                          id="salePointTypeTitle"
                        />
                      </div>
                    </a>
                  </Link>
                  <Link to="/etiquetas" style={{ textDecoration: "none" }}>
                    <a href="/etiquetas" className="link-nav-item">
                      <StyleIcon className="menu-icon"></StyleIcon>
                      <div className="text-block textovertialnavbar">
                        <FormattedMessage
                          className="navbar-option"
                          id="tagsTitle"
                        />
                      </div> 
                    </a>
                  </Link>
                  
                  <Link to="/trabajadores" style={{ textDecoration: "none" }}>
                    <a href="/trabajadores" className="link-nav-item">
                      <img src={groupIcon} alt="" className="menu-icon" />
                      <div className="text-block textovertialnavbar">
                        <FormattedMessage
                          className="navbar-option"
                          id="workersTitle"
                        />
                      </div>
                    </a>
                  </Link>
                  <Link to="/productos" style={{ textDecoration: "none" }}>
                    <a href="/productos" className="link-nav-item">
                      <img src={foodIcon} alt="" className="menu-icon" />
                      <div className="text-block textovertialnavbar">
                        <FormattedMessage
                          className="navbar-option"
                          id="productsTitle"
                        />
                      </div>
                    </a>
                  </Link>

                  <Link to="/inventarios" style={{ textDecoration: "none" }}>
                    <a href="/inventarios" className="link-nav-item">
                    <Inventory2Icon className="menu-icon"></Inventory2Icon>

                      <div className="text-block textovertialnavbar">
                        <FormattedMessage
                          className="navbar-option"
                          id="inventoryTitle"
                        />
                      </div>
                    </a>
                  </Link>
                  <Link to="/billeteras" style={{ textDecoration: "none" }}>
                    <a href="/billeteras" className="link-nav-item">
                      <WalletIcon className="menu-icon"></WalletIcon>
                      <div className="text-block textovertialnavbar">
                        <FormattedMessage
                          className="navbar-option"
                          id="walletsTitle"
                        />
                      </div>
                    </a>
                  </Link>
                  <Link to="/informes" style={{ textDecoration: "none" }}>
                    <a href="/informes" className="link-nav-item">
                      <img
                        src="https://uploads-ssl.webflow.com/5ee80e765cd1c56a0214b32d/5eeadd98a317b72a3e03c8bd_payment-24px.svg"
                        alt=""
                        className="menu-icon"
                      />
                      <div className="text-block textovertialnavbar">
                        <FormattedMessage
                          className="navbar-option"
                          id="operationalReportsTitle"
                        />
                      </div>
                    </a>
                  </Link>
                </div>
                <div className="pagos"></div>
              </div>
            )}

            {userRole === "2" && (
              <div className="gestion">
                <h5 className="titulo-sec-nav-bar">
                  <FormattedMessage
                    className="navbar-option"
                    id="managementTitle"
                  />
                </h5>
                <Link to="/organizaciones" style={{ textDecoration: "none" }}>
                  <a href="/organizaciones" className="link-nav-item">
                    <img src={foodHouseicon} alt="" className="menu-icon" />
                    <div className="text-block textovertialnavbar">
                      <FormattedMessage
                        className="navbar-option"
                        id="organizationTitle"
                      />
                    </div>
                  </a>
                </Link>
                <Link to="/supervisores" style={{ textDecoration: "none" }}>
                  <a href="/supervisores" className="link-nav-item">
                    <img src={groupIcon} alt="" className="menu-icon" />
                    <div className="text-block textovertialnavbar">
                      <FormattedMessage
                        className="navbar-option"
                        id="Supervisor"
                      />
                    </div>
                  </a>
                </Link>
              </div>
            )}
            <div id="logout-div">
              <a id="logoutBtn" href=" " className="link-nav-item">
                <img
                  src="https://uploads-ssl.webflow.com/5ee80e765cd1c56a0214b32d/5efcd3ed8bd97b2bdd208f4b_exit_to_app-white-18dp.svg"
                  alt=""
                  className="menu-icon"
                />
                <div className="text-block textovertialnavbar">
                  <Link onClick={handleClickLogOut} className="vertical-link">
                    <FormattedMessage
                      className="navbar-option"
                      id="logOutTitle"
                    />
                  </Link>
                </div>
              </a>
              <div className="titulo-sec-nav-bar">v 1.3.0</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
//     <div
//       data-collapse="medium"
//       data-animation="default"
//       data-duration="400"
//       role="banner"
//       className="vertical_div_menu col-2 navbar"
//       id="wrapper"
//     >
//       <div className="menu_container">
//         <Link to="/home">
//           <p aria-current="page" className="brand">
//             <img src={Logo} alt="logo_img" className="img_logo" />
//           </p>
//         </Link>
//         {userRole === "1" && (
//           <div>
//             <div className="gestion">
//               <h5 className="titulo-sec-nav-bar">
//                 <FormattedMessage
//                   className="navbar-option"
//                   id="managementTitle"
//                 />
//               </h5>
//               <Link to="/pasillos" style={{ textDecoration: "none" }}>
//                 <a href="/pasillos" className="link-nav-item">
//                   <img src={foodHouseicon} alt="" className="menu-icon" />
//                   <div className="text-block textovertialnavbar">
//                     <FormattedMessage
//                       className="navbar-option"
//                       id="salePointTypeTitle"
//                     />
//                   </div>
//                 </a>
//               </Link>
//               <Link to="/trabajadores" style={{ textDecoration: "none" }}>
//                 <a href="/trabajadores" className="link-nav-item">
//                   <img src={groupIcon} alt="" className="menu-icon" />
//                   <div className="text-block textovertialnavbar">
//                     <FormattedMessage
//                       className="navbar-option"
//                       id="workersTitle"
//                     />
//                   </div>
//                 </a>
//               </Link>
//               <Link to="/productos" style={{ textDecoration: "none" }}>
//                 <a href="/productos" className="link-nav-item">
//                   <img src={foodIcon} alt="" className="menu-icon" />
//                   <div className="text-block textovertialnavbar">
//                     <FormattedMessage
//                       className="navbar-option"
//                       id="productsTitle"
//                     />
//                   </div>
//                 </a>
//               </Link>

//               <Link to="/inventarios" style={{ textDecoration: "none" }}>
//                 <a href="/inventarios" className="link-nav-item">
//                   <img
//                     src="https://uploads-ssl.webflow.com/5ee80e765cd1c56a0214b32d/5eeadd98b4f133b9c87c6870_local_offer-24px.svg"
//                     alt=""
//                     className="menu-icon"
//                   />
//                   <div className="text-block textovertialnavbar">
//                     <FormattedMessage
//                       className="navbar-option"
//                       id="inventoryTitle"
//                     />
//                   </div>
//                 </a>
//               </Link>
//             </div>
//             <div className="pagos"></div>
//           </div>
//         )}

//         {userRole === "2" && (
//           <div className="gestion">
//             <h5 className="titulo-sec-nav-bar">
//               <FormattedMessage
//                 className="navbar-option"
//                 id="managementTitle"
//               />
//             </h5>
//             <Link to="/organizaciones" style={{ textDecoration: "none" }}>
//               <a href="/organizaciones" className="link-nav-item">
//                 <img src={foodHouseicon} alt="" className="menu-icon" />
//                 <div className="text-block textovertialnavbar">
//                   <FormattedMessage
//                     className="navbar-option"
//                     id="organizationTitle"
//                   />
//                 </div>
//               </a>
//             </Link>
//             <Link to="/supervisores" style={{ textDecoration: "none" }}>
//               <a href="/supervisores" className="link-nav-item">
//                 <img src={groupIcon} alt="" className="menu-icon" />
//                 <div className="text-block textovertialnavbar">
//                   <FormattedMessage className="navbar-option" id="Supervisor" />
//                 </div>
//               </a>
//             </Link>
//           </div>
//         )}
//         <div id="logout-div">
//           <a id="logoutBtn" href=" " className="link-nav-item">
//             <img
//               src="https://uploads-ssl.webflow.com/5ee80e765cd1c56a0214b32d/5efcd3ed8bd97b2bdd208f4b_exit_to_app-white-18dp.svg"
//               alt=""
//               className="menu-icon"
//             />
//             <div className="text-block textovertialnavbar">
//               <Link onClick={handleClickLogOut} className="vertical-link">
//                 <FormattedMessage className="navbar-option" id="logOutTitle" />
//               </Link>
//             </div>
//           </a>
//           <div className="titulo-sec-nav-bar">v 1.3.0</div>
//         </div>
//       </div>
//     </div>
//   );
// };

export default Navbar;
