import "./CashCount.scss";

import {Line, ResponsiveLine} from "@nivo/line";
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import {FormControlLabel, MenuItem, Select} from "@material-ui/core";
import TextField from "@mui/material/TextField";

import {DateRangePickerComponent} from "@syncfusion/ej2-react-calendars";

import DateRangePicker from "@mui/lab/DateRangePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import {getDay} from "date-fns";

import React, {Fragment, useEffect, useState} from "react";
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import {getSalePointsOfOrganization} from "../../services/utilsFranchise/utilsFranchise";
import {DatePicker} from "material-ui/DatePicker";
import YearMonthPicker from "../../components/MontYearDateRange/MontYearDateRange";
import {getCashHistogram, getCashMovements} from "../../services/utilsInformation/utilsInformation";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import CustomTable from "../../components/Table/Table";
import CurrencyFormat from "react-currency-format";

const CashCount = () => {
    const [franchiseList, setFranchiseList] = useState([]);

    const [franchiseSelected, setFranchiseSelected] = useState(0);

    const [typeOfRangeSelected, setTypeOfRangeSelected] = useState(0);

    const [tableLoading, setTableLoading] = useState(true);

    const [oneDaySelected, setOneDaySelected] = useState("");

    const [rangeDatesSelected, setRangeDatesSelected] = useState([]);

    const [submitted, setSubmitted] = useState(false);

    const [visualizationMode, setVisualizationMode] = useState(1);

    const [tableData, setTableData] = useState([
        {
            id: "",
            color: "",
            data: [
                {x: "", y: ""},
                {x: "", y: ""},
            ],
        },
    ]);

    const [cashMovemetsData, setCashMovementsData] = useState([]);

    let months = [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septimebre",
        "Octubre",
        "Noviembre",
        "Diciembre",
    ];

    let days = ["Lun", "Mar", "Mier", "Jue", "Vier", "Sab", "Dom"];

    const cashTableHeaders = [
        'dateHeader',
        'movementTypeHeader',
        'valueHeader',
        'cashAvailableHeader',
        'referenceHeader',
    ];

    const createMovementsTableData = (date, typeOfMovement, value, cashAvialable, reference) =>{
        return {date, typeOfMovement, value:(
                <CurrencyFormat
                    value={value}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                />
            ), cashAvialable: (<CurrencyFormat
                value={cashAvialable}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
            />), reference};
    }
    /**
     * This is for the datepicker
     * @param {*} franchise
     */
    const startValue = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        14
    );
    const endValue = new Date(
        new Date().getFullYear(),
        new Date().getMonth() + 1,
        -15
    );

    const fetchData = async (franchise) => {
        getSalePointsOfOrganization().then((data) => {
            setFranchiseList(data["franchises"]);
        });
    };

    const handleChangeFranchise = (event) => {
        setTableLoading(true);
        setFranchiseSelected(event.target.value);
    };

    const handleTypeRange = (event) => {
        //setTableLoading(true);
        setTypeOfRangeSelected(event.target.value);
    };

    const handleOneDaySelected = (event) => {
        setOneDaySelected(event.target.value);
    };

    let getDateFormat = (date) => {
        let day = date.getDay();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    let getFormattedDate = (date) => {
        console.log(date);
        return date.toISOString().split("T")[0];
    };

    let monthName = (monthNumber) => {
        return months[monthNumber];
    };
    let formatHourAMPM = (time) => {
        let hours = time.split(":")[0];
        let ampm = hours >= 12 ? "pm" : "am";
        hours = hours % 12;
        hours = hours ? hours : 12;
        let strTime = hours + " " + ampm;
        return strTime;
    };

    useEffect(() => {
        fetchData(franchiseSelected).then(() => {
        });
    }, [franchiseSelected]);

    const handleVisualizationMode = (event) => {
        setVisualizationMode(event.target.value);
        // let tableView = seeTable;
        //setSeeTable(!tableView);
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        /**
         * Vamos a hacer condicionales para los 3 diferentes casos
         */
        setSubmitted(true);
        if (typeOfRangeSelected === 1) {
            //Cuando solo queremo sbuscarlo para la misma fecha
            setTableLoading(true);
            getCashHistogram(franchiseSelected, oneDaySelected, oneDaySelected).then(
                (data) => {

                    let example = "2018-07-07T00:00:00";
                    let dataObj = data.cash_histogram;
                    let dataTuple = [];
                    for (let key in dataObj) {
                        let value = dataObj[key];
                        let hourVar = value.hour.split("T")[1];
                        let data = {
                            x: formatHourAMPM(hourVar),
                            y: value.counts.money_available,
                        };
                        dataTuple.push(data);
                    }
                    let graphObject = [
                        {
                            id: "Efectivo",
                            data: dataTuple,
                        },
                    ];

                    setTableData(graphObject);


                }
            );

            getCashMovements(franchiseSelected, oneDaySelected, oneDaySelected).then(
                (data) =>{
                    let arrayOfMovements = [];
                    arrayOfMovements = data.respuesta.map((cashMovement) =>
                        createMovementsTableData(cashMovement.Fecha, cashMovement.Tipo, cashMovement.Valor, cashMovement.Disponible, cashMovement.Referencia)
                    );
                    setCashMovementsData(arrayOfMovements);
                    setTableLoading(false);
                }
            )


        } else if (typeOfRangeSelected === 2) {
            //Rango de dias
            let startDateFormatted = getFormattedDate(rangeDatesSelected[0]);
            let endDateFormatted = getFormattedDate(rangeDatesSelected[1]);
            setTableLoading(true);
            getCashHistogram(
                franchiseSelected,
                startDateFormatted,
                endDateFormatted
            ).then((data) => {
                console.log("data del mismo dia", data);
                let example = "2018-07-07T00:00:00";
                console.log(data);
                console.log(example.split("T")[0]);
                let dataObj = data.cash_histogram;
                let dataTuple = [];
                for (let key in dataObj) {
                    let value = dataObj[key];
                    let dateValue = value.day.split("T")[0];
                    let dateObj = new Date(dateValue);
                    let data = {
                        x: days[dateObj.getDay()] + "-" + dateObj.getDate(),
                        y: value.counts.money_available,
                    };
                    dataTuple.push(data);
                }
                let graphObject = [
                    {
                        id: "Efectivo",
                        data: dataTuple,
                    },
                ];

                setTableData(graphObject);
                setTableLoading(false);

            });

            getCashMovements(franchiseSelected, startDateFormatted, endDateFormatted).then(
                (data) =>{
                    console.log(data.respuesta)
                    let arrayOfMovements = [];
                    arrayOfMovements = data.respuesta.map((cashMovement) =>
                        createMovementsTableData(cashMovement.Fecha, cashMovement.Tipo, cashMovement.Valor, cashMovement.Disponible, cashMovement.Referencia)
                    );
                    setCashMovementsData(arrayOfMovements);
                    setTableLoading(false);
                }
            )
        } else if (typeOfRangeSelected === 3) {
            setTableLoading(true);
            let startDateFormatted = getFormattedDate(rangeDatesSelected[0]);
            let endDateFormatted = getFormattedDate(rangeDatesSelected[1]);

            getCashHistogram(
                franchiseSelected,
                startDateFormatted,
                endDateFormatted
            ).then((data) => {
                console.log(data);
                let dataObj = data.cash_histogram;
                let dataTuple = [];
                for (let key in dataObj) {
                    let value = dataObj[key];
                    let data = {
                        x: monthName(value.month - 1),
                        y: value.counts.money_available,
                    };
                    dataTuple.push(data);
                }
                let graphObject = [
                    {
                        id: "Inventario",
                        data: dataTuple,
                    },
                ];

                setTableData(graphObject);
                setTableLoading(false);
            });

            getCashMovements(franchiseSelected, startDateFormatted, endDateFormatted).then(
                (data) =>{
                    console.log(data.respuesta)
                    let arrayOfMovements = [];
                    arrayOfMovements = data.respuesta.map((cashMovement) =>
                    createMovementsTableData(cashMovement.Fecha, cashMovement.Tipo, cashMovement.Valor, cashMovement.Disponible, cashMovement.Referencia)
                    );

                    setCashMovementsData(arrayOfMovements);
                    setTableLoading(false);
                }
            )
        }
    };

    return (
        <main className="section-body">
            <h1 className="section-title">Seguimiento de efectivo</h1>
            <form onSubmit={handleSubmit} action="">
                <div className="row">
                    <div className="col-6 mb-4">
                        <label className="form-label" htmlFor="franchiseInput">
                            <FormattedMessage id="franchiseLbl"/>
                        </label>
                        <br/>
                        <Select
                            required
                            value={franchiseSelected}
                            name="fk_franchise"
                            onChange={handleChangeFranchise}
                            className="worker-form-select"
                            id="franchiseInput"
                        >
                            {franchiseList &&
                                franchiseList.map((franchise, i) => (
                                    <MenuItem key={i} value={franchise.id}>
                                        {franchise.name}
                                    </MenuItem>
                                ))}
                        </Select>
                        <br/>
                    </div>

                </div>
                <div className="row">
                    <div className="col-6 mb-4">


                        {franchiseSelected !== 0 && (
                            <div>
                                <label className="form-label" htmlFor="franchiseInput">
                                    <FormattedMessage id="typeOfRange"/>
                                </label>
                                <br/>
                                <Select
                                    required
                                    value={typeOfRangeSelected}
                                    name="fk_franchise"
                                    onChange={handleTypeRange}
                                    className="worker-form-select"
                                    id="franchiseInput"
                                >
                                    <MenuItem key={1} value={1}>
                                        Dia
                                    </MenuItem>
                                    <MenuItem key={2} value={2}>
                                        Rango de dias (Max. 45 dias)
                                    </MenuItem>
                                    <MenuItem key={3} value={3}>
                                        Rango de meses
                                    </MenuItem>
                                </Select>
                            </div>
                        )}
                    </div>
                    <div className="col-6 mb-4">
                        {typeOfRangeSelected === 1 && (
                            <div className="container-input row">
                                <label
                                    className="form-label no-padding-6"
                                    htmlFor="franchiseInput"
                                >
                                    <FormattedMessage id="finalDateLbl"/>
                                </label>
                                <input
                                    required
                                    type="date"
                                    value={oneDaySelected}
                                    name="end_date"
                                    onChange={handleOneDaySelected}
                                    className="worker-form-select"
                                />
                            </div>
                        )}
                        {typeOfRangeSelected === 2 && (
                            <div className="range-date-container">
                                <label className="form-label" htmlFor="franchiseInput">
                                    <FormattedMessage id="selectRangeDate"/>
                                </label>
                                <DateRangePickerComponent
                                    placeholder="Enter Date Range"
                                    startDate={startValue}
                                    endDate={endValue}
                                    minDays={2}
                                    maxDays={45}
                                    format="dd-MMM-yy"
                                    onChange={(date) => {
                                        console.log(date.value);
                                        setRangeDatesSelected(date.value);
                                    }}
                                />
                            </div>

                        )}
                        {typeOfRangeSelected === 3 && (
                            <div className="range-date-container">
                                <label className="form-label" htmlFor="franchiseInput">
                                    <FormattedMessage id="selectRangeDate"/>
                                </label>
                                <DateRangePickerComponent
                                    placeholder="Enter Date Range"
                                    startDate={startValue}
                                    endDate={endValue}
                                    minDays={30}
                                    maxDays={356}
                                    format="dd-MMM-yy"
                                    onChange={(date) => {
                                        setRangeDatesSelected(date.value);
                                    }}
                                    //Uncomment below code to show month range picker. Also comment the properties min, max, mindays and maxdays
                                    start="Year"
                                    depth="Year"
                                />
                            </div>
                        )}

                    </div>
                    <div className="center-items">
                        {!submitted && (<button className="btn btn-primary m-2" type="submit">
                            CREAR HISTOGRAMA
                        </button>)}
                        {!tableLoading && tableData && (<button className="btn btn-primary m-2" type="submit">
                            CREAR NUEVO HISTOGRAMA
                        </button>)}
                    </div>
                </div>
                <div className="row">
                    {tableData && !tableLoading && (
                        <>
                            <article className="flex">
                                <RadioGroup
                                    aria-label="gender"
                                    name="controlled-radio-buttons-group"
                                    value={visualizationMode}
                                    onChange={handleVisualizationMode}
                                    className="flex"
                                >

                                    <FormControlLabel
                                        value={1}
                                        control={<Radio color="primary"/>}
                                        label={<FormattedMessage id="graphLbl"/>}
                                    />
                                    <FormControlLabel
                                        value={2}
                                        control={<Radio color="primary"/>}
                                        label={<FormattedMessage id="tableLbl"/>}
                                        className="font-muli"
                                    />
                                </RadioGroup>
                            </article>
                        </>
                    )}
                    {tableLoading && submitted && (
                        <div className="center-div">
                            <Stack m={{color: 'grey.500'}}>
                                <CircularProgress color="secondary" className="spinner-primary spinner-size"/>
                            </Stack></div>)}
                    {tableData && !tableLoading && visualizationMode == 1 && (
                        <>
                            <div className="graph-container">
                                <div className="graph-tamanio">
                                    <ResponsiveLine
                                        data={tableData}
                                        margin={{top: 50, right: 110, bottom: 50, left: 60}}
                                        xScale={{type: "point"}}
                                        yScale={{
                                            type: "linear",
                                            min: "auto",
                                            max: "auto",
                                            stacked: true,
                                            reverse: false,
                                        }}
                                        yFormat=">-$0,.2~f"
                                        axisTop={null}
                                        axisRight={null}
                                        axisBottom={{
                                            orient: "bottom",
                                            tickSize: 5,
                                            tickPadding: 5,
                                            tickRotation: 0,
                                            legend: "Mes",
                                            legendOffset: 36,
                                            legendPosition: "middle",
                                        }}
                                        enableGridX={false}
                                        axisLeft={null}
                                        pointSize={10}
                                        colors={["#0070A1", "#00A1D1", "#FF003A"]}
                                        pointColor="#0070A1"
                                        pointBorderWidth={2}
                                        pointBorderColor={{from: "serieColor"}}
                                        pointLabelYOffset={-12}
                                        useMesh={true}
                                        legends={[
                                            {
                                                anchor: "bottom-right",
                                                direction: "column",
                                                justify: false,
                                                translateX: 100,
                                                translateY: 0,
                                                itemsSpacing: 0,
                                                itemDirection: "left-to-right",
                                                itemWidth: 80,
                                                itemHeight: 20,
                                                itemOpacity: 0.75,
                                                symbolSize: 12,
                                                symbolShape: "circle",

                                                symbolBorderColor: "rgba(0, 0, 0, .5)",
                                                effects: [
                                                    {
                                                        on: "hover",
                                                        style: {
                                                            itemBackground: "rgba(0, 0, 0, .03)",
                                                            itemOpacity: 1,
                                                        },
                                                    },
                                                ],
                                            },
                                        ]}
                                    />
                                </div>
                            </div>
                        </>
                    )}

                    {
                        tableData && !tableLoading && visualizationMode == 2 && (
                            <>
                                <div className="table-size">

                                        <CustomTable
                                            headers={cashTableHeaders}
                                            rows={cashMovemetsData}
                                            pagination={''}
                                        />

                                </div>
                            </>
                        )
                    }


                </div>
            </form>
        </main>
    );
};

export default CashCount;
