import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import ImagenDefult from '../../assets/market-icons/producto.jpg';
import Paper from "@material-ui/core/Paper";
import {
  MenuItem,
  Select,
  TableFooter,
  TableHead,
  TablePagination,
  TextField,
} from "@material-ui/core";
import { FormattedMessage } from "react-intl";
/**
 * Styles import
 */
import "./Table.scss";
const tableStyles = makeStyles({
  table: {
    minWidth: 500,
    fontFamily: "Muli-Regular",
  },
});

export default function CustomTable(props) {
  // ----------------------------------------------------
  // ------------- Variables & Constants ----------------
  // ----------------------------------------------------

  const classes = tableStyles();

  const headers = props.headers;

  const page = props.pagination.page;

  const rowsPerPage = props.pagination.rowsPerPage;

  const rows = props.rows;

  const rowTypes = props.rowTypes;

  const count = props.pagination.count;

  // ----------------------------------------------------
  // ------------------- Handlers -----------------------
  // ----------------------------------------------------

  const handleChangePage = (event, newPage) => {
    props.handleChangePage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    props.handleChangeRowsPerPage(parseInt(event.target.value));
  };

  // ----------------------------------------------------
  // -------------------- Render ------------------------
  // ----------------------------------------------------

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell key={header} className="color-header">
                  <FormattedMessage id={header} />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <CustomRow data={row} types={rowTypes} table={rows}></CustomRow>
              </TableRow>
            ))}
          </TableBody>
          {page > -1 && (
            <TableFooter>
              <TablePagination
                component="div"
                count={count}
                page={page}
                rowsPerPage={rowsPerPage || 5}
                rowsPerPageOptions={[5, 10, 20, 15]}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </>
  );
}

// ----------------------------------------------------
// ------------------ Custom Row ----------------------
// ----------------------------------------------------

function CustomRow(props) {
  const { data, table } = props;
  let { types } = props;
  const properties = Object.keys(data);
  const dataLength = properties.length;
  

  if (types) {
    if (types.length !== dataLength) {
      let i = 0;
      types = Array.from(properties, (property, index) => {
        let type = "text";

        if (index === types[i][0]) {
          type = types[i][1];
          i = i < types.length - 1 ? i + 1 : i;
        }

        return type;
      });
    }
  } else {
    types = Array.from(properties, (property) => "text");
  }

  const rowTypeRenderer = (data, rowType = "text") => {
    let renderObject = <></>;

    if (rowType === "text") {
      renderObject = [data];
    } else if (rowType === "select") {
      const { value, name, onChange, options, rowId } = data;
      renderObject = [
        <Select
          style={{ width: 189 }}
          value={value ? value : 0}
          name={`${rowId}-${name}`}
          onChange={(event) => {
            console.log(table);
            onChange(event, table, rowId);
          }}
          id={`${name}Input`}
        >
          {options.map((option, index) => (
            <MenuItem key={index} value={option[1]}>
              {option[0]}
            </MenuItem>
          ))}
        </Select>
      ];
    } else if (rowType === "image") {
      let errorImg = ImagenDefult;
      let imageSrc = data || errorImg;
      renderObject = [<img src={imageSrc} alt="img_product" className="row-img" onError={(e)=>e.target.src = errorImg} />];
    }
     else if (rowType.includes('textfield')) {
      if(rowType.includes('number')){
        
        let value = data[0];
        let name = data[1];
        let onChangeFunction = data[2];
        let rowId = data[3];
        
        renderObject = (
          <div className="row">
            <div className="col">
              <TextField
                required
                className="form-control"
                value={value ? value: 0}
                name={`${rowId}-${name}`}
                type={"number"}
                onChange={(event)=>{ 
                                 
                  onChangeFunction(event, table, rowId)
                }}
              />
            </div>
            {/* <div className="col-3">
              <p className={"muli-font"}>UND</p>
            </div> */}
          </div>
        );
        
      }else{
        
        
        let value = data[0];
        let name = data[1];
        let onChangeFunction = data[2];
        let rowId = data[3];

        renderObject = [
          <TextField
            required
            className="form-control"
            value={value ? value : ""}
            name={`${rowId}-${name}`}
            onChange={(event) => {
              onChangeFunction(event, table, rowId);
            }}
          />
        ];
      }
      
    }
    return renderObject;
  };

  const onSelectChange = (event) => {};

  return (
    <>
      {properties.map((property, index) => (
        <TableCell key={property}>
          {" "}
          {rowTypeRenderer(data[property], types[index])}{" "}
        </TableCell>
      ))}
    </>
  );
}

export const convertToSelect = (value, name, onChange, options, rowId) => {
  return { value, name, onChange, options, rowId };
};

export const convertToTextField = (value, name, onChange, rowId) =>{
  return [value, name, onChange, rowId]
}