import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
} from "@mui/material";

import { useEffect, useState } from "react";
import { getSalePointsOfOrganization } from "../../services/utilsFranchise/utilsFranchise";
import { date } from "joi";
import SearchBar from "../../components/searchBar/searchBar";
import moment from "moment";
import { TextField } from "@material-ui/core";
import { FormattedMessage } from "react-intl";

const StockForm = ({
  setFranchiseInformation,
  searchValue,
  onChangeSearch,
  dates,
  setDates,
                     pageStateCritial, providerName
}) => {
  const [age, setAge] = useState("");
  const [franchisesData, setFranshisesData] = useState([]);
  const [selectedFranchise, setSelectedFranchise] = useState(0);

  const fetchData = () => {
    getSalePointsOfOrganization().then((data) => {
      console.log(data, "fetchfranchises");
      setFranshisesData(data["franchises"]);
    });
  };

  const getMonthsInYearFromNow = () => {
    let today = new Date();

    console.log(today.getMonth() - 12);
    console.log(moment.months());

    var months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    for (let i = 12; i > 0; i--) {
      var d = new Date();
      let aYearFromNowdate = new Date(d.setMonth(d.getMonth() - i));
      let yearDate = aYearFromNowdate.getFullYear();
      let monthDate = aYearFromNowdate.getMonth();

    }

    var d = new Date();
    let aYearFromNowdate = new Date(d.setMonth(d.getMonth() - 10));
  };

  useEffect(() => {
    fetchData();
    getMonthsInYearFromNow();
  }, []);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleChangeFranchise = (event) => {
    setSelectedFranchise(event.target.value);
    if (event.target.value === 0) {
      setFranchiseInformation({ name: "", id: 0 });
    } else {
      let franchiseFound = franchisesData.find(
        (franchise) => franchise.id === event.target.value
      );
      setFranchiseInformation({
        name: franchiseFound.name,
        id: franchiseFound.id,
      });
    }
    //setFranchiseInformation(franchisesData[event.target.value]);
  };

  const handleDateChange = (event) => {
    let initialDate = dates.date_initial;
    if (event.target.name === "date_final") {
      if (initialDate <= event.target.value) {
        setDates({ ...dates, [event.target.name]: event.target.value });
      } else {
        setDates({ ...dates, [event.target.name]: "" });
      }
    } else {
      setDates({ ...dates, [event.target.name]: event.target.value });
    }
  };

  return (
    <from className="form-filters flex">
      { pageStateCritial===1 && <article className="flex">
        <TextField
            id="date"
            label={"Fecha inicial"}
            type="date"
            name="date_initial"
            className="form-control input-margin input"
            onChange={handleDateChange}
            value={dates.date_initial ? dates.date_initial : ""}
            InputLabelProps={{
              shrink: true,
            }}
        />
        <TextField
            id="date"
            disabled={dates.date_initial === ""}
            label={"Fecha final"}
            type="date"
            name="date_final"
            className="form-control input-margin input"
            onChange={handleDateChange}
            value={dates.date_final ? dates.date_final : ""}
            InputLabelProps={{
              shrink: true,
            }}
        />
      </article>}
      { pageStateCritial ===1 && <FormControl variant="standard">
        <div className="space-12">Buscador producto</div>
        <SearchBar
            input={searchValue}
            onChange={onChangeSearch}
            nombre=""
            disabled={dates.date_initial === "" && dates.date_final === ""}
        />
      </FormControl>}
      {pageStateCritial===2 &&(
          <div>
            <h1 className={"no-margin"}>
              {providerName}
            </h1>

          </div>
      )}

      <FormControl variant="standard">
        <InputLabel id="demo-simple-select-standard-label" color="secondary">
          Punto de venta
        </InputLabel>

        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={selectedFranchise}
          onChange={handleChangeFranchise}
          label="Punto de venta"
          color="secondary"
          className="input"

        >
          <MenuItem value={0} key={0}>
            Todas
          </MenuItem>
          {franchisesData.map((franchise, indexFranchise) => {
            return (
              <MenuItem value={franchise.id} key={indexFranchise}>
                {franchise.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      
      
      
    </from>
  );
};

export default StockForm;
